import styled, { css } from 'styled-components';

import { WysiwygStyleWrapper } from '@powdr/components';
import { Icon } from '@powdr/components/icon';
import {
  Breakpoints,
  Components,
  ComponentGroups,
  ThemeProps as Prop,
} from '@powdr/constants';
import { themeUtil, componentFontSize } from '@powdr/utils';

const CPNT = Components.UI_HEADER;

const getAlignment = (alignment = 'center') => {
  const alignmentMapping = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end',
  };

  return alignmentMapping[alignment] || alignmentMapping.center;
};

export const StyledUiHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ alignment }) => getAlignment(alignment)};
  padding: ${({ $paddingTop, $paddingBottom }) => `${$paddingTop}px 0px ${$paddingBottom}px`};

  iframe {
    overflow: hidden;
  }
`;

export const StyledIcon = styled(Icon)`
  margin-bottom: 30px;

  svg {
    fill: ${({ colorProfile }) => themeUtil(Prop.ICON, CPNT, colorProfile)};
    color: ${({ colorProfile }) => themeUtil(Prop.ICON, CPNT, colorProfile)};
  }
`;

export const StyledHeaderContent = styled(WysiwygStyleWrapper)`
  text-align: ${({ $alignment }) => $alignment || 'center'};
`;
