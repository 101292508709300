import PropTypes from 'prop-types';
import React, { useRef, useContext, useMemo } from 'react';

import { StickyNav } from '@powdr/components';
import { AppContext } from '@powdr/context';
import { useIsWrapperMinimized, useSeason } from '@powdr/hooks';
import { getActiveLinkAtSetDepth } from '@powdr/utils';
import { useStaticData } from '@powdr/web/src/hooks';

import { HeaderLogo, DorToggle } from './components';
import {
  StyledHeader, NavigationDrawerToggle,
  HeaderLayoutWrapper, DrawerText,
  HeaderLeft, HeaderCenter, HeaderRight,
  NavigationDrawerToggleIcon,
} from './styles';

export const Header = ({
  levels,
  showStickyNav,
}) => {
  const {
    setDrawerOpen,
    setNavigationDrawerOpen,
    setDorDrawerOpen,
  } = useContext(AppContext);
  const {
    navigationDrawer: {
      navigation,
    },
    headerConfig,
  } = useStaticData();
  const headerRef = useRef(null);
  const isWrapperMinimized = useIsWrapperMinimized();
  const { checkSeasonality } = useSeason();

  const stickyNav = useMemo(
    () => getActiveLinkAtSetDepth(navigation?.childPages, levels, 1)?.childPages
      ?.filter((page) => !page.isHideFromNav && checkSeasonality(page.season)) || [],
    [checkSeasonality, levels, navigation],
  );

  const pageNav = useMemo(
    () => getActiveLinkAtSetDepth(stickyNav, levels, 2)?.childPages
      ?.filter((page) => !page.isHideFromNav && checkSeasonality(page.season)) || [],
    [checkSeasonality, levels, stickyNav],
  );

  return (
    <StyledHeader
      ref={headerRef}
      $opacity={(isWrapperMinimized)
        ? headerConfig?.headerOpacityMinimized
        : headerConfig?.headerOpacityUnminimized}
      $minimize={isWrapperMinimized}
      $showStickyNav={showStickyNav}
    >
      <HeaderLayoutWrapper $minimize={isWrapperMinimized}>
        {(headerConfig?.isDorFeedEnabled) && (
          <HeaderLeft>
            <DorToggle
              title="Open DOR Drawer"
              onClick={() => setDrawerOpen(true, setDorDrawerOpen)}
            />
          </HeaderLeft>
        )}
        <HeaderCenter $isLeftJustified={!headerConfig?.isDorFeedEnabled}>
          <HeaderLogo />
        </HeaderCenter>
        <HeaderRight>
          <NavigationDrawerToggle
            title="Open Navigation Drawer"
            onClick={() => setDrawerOpen(true, setNavigationDrawerOpen)}
          >
            <NavigationDrawerToggleIcon name="ui-menu" />
            <DrawerText>Menu</DrawerText>
          </NavigationDrawerToggle>
        </HeaderRight>
      </HeaderLayoutWrapper>
      {(showStickyNav)
        && <StickyNav levels={levels} data={stickyNav} mobileInnerNavData={pageNav} removeSticky />}
    </StyledHeader>
  );
};

Header.propTypes = {
  levels: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  showStickyNav: PropTypes.bool.isRequired,
};

Header.defaultProps = {};
