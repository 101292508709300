/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';

import { Icon, WysiwygStyleWrapper } from '@powdr/components';
import {
  ThemeProps as Prop, Components, Breakpoints, ComponentGroups, Layouts,
  FontSize,
  FontFamily,
} from '@powdr/constants';
import {
  themeUtil, componentFontSize, clamp, getFontStyles,
} from '@powdr/utils';

const CPNT = Components.DOR_SNOW_REPORT;

export const StyledDorSnowReport = styled.div`
  display: grid;
  margin-bottom: 25px;

  ${Breakpoints.desktopOnly(css`
    ${({ $layout, $isParentNavFeed }) => ($isParentNavFeed
    ? 'grid-template-columns: 65% 35%;'
    : ($layout === Layouts.STACKED)
      ? 'grid-template-columns: repeat(1, 1fr);'
      : 'grid-template-columns: repeat(2, 1fr);')};
  `)}

  ${Breakpoints.small(css`
    ${({ $isParentNavFeed }) => ($isParentNavFeed && 'grid-template-columns: 45% 55%;')};
  `)}
`;

export const DataSection = styled.div``;

export const StaticContent = styled(WysiwygStyleWrapper)``;

export const ReportSection = styled(WysiwygStyleWrapper)`
  ${({ $isParentNavFeed }) => ($isParentNavFeed) && 'margin-left: 15px'};

  p {
    ${({ $isParentNavFeed }) => ($isParentNavFeed) && `
      ${getFontStyles(FontFamily.BASE_FONT, FontSize.REG40)};

      ${Breakpoints.large(css`
        ${getFontStyles(FontFamily.BASE_FONT, FontSize.REG40)};
      `)}

      ${Breakpoints.larger(css`
        ${getFontStyles(FontFamily.BASE_FONT, FontSize.REG40)};
      `)}
    `}
  }
`;

export const Timestamp = styled.small`
  display: block;
  line-height: 1;
  color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT, CPNT, colorProfile)};
  margin-bottom: 15px;
`;

export const ReportDataItems = styled.ul`
  display: grid;
  margin-bottom: 35px;
  gap: 5px;
  ${Breakpoints.desktopOnly(css`
    ${({ $layout, $numItems }) => (($layout === Layouts.STACKED)
    ? `grid-template-columns: repeat(${$numItems}, 1fr);`
    : 'grid-template-columns: repeat(2, 1fr); gap: 75px;')};
  `)};

  ${Breakpoints.small(css`
    ${({ $layout, $numItems }) => ($layout === Layouts.STACKED) && `grid-template-columns: repeat(${$numItems / 2}, 1fr);`}
  `)}

  ${Breakpoints.mobile(css`
    grid-template-columns: repeat(4, 1fr);
    row-gap: 50px;
    column-gap: 10px;
  `)}
`;

export const ReportDataItem = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: ${({ $colorProfile }) => themeUtil(Prop.CONTENT, CPNT, $colorProfile)};

  h3 {
    ${() => getFontStyles(FontFamily.BASE_FONT, FontSize.REG40, undefined, undefined)};
    margin-bottom: 15px;
  }

  p {
    ${() => getFontStyles(FontFamily.PRIMARY_FONT, '84px', undefined, undefined)};
    line-height: 1;
  }

  svg {
    fill: ${({ $colorProfile }) => themeUtil(Prop.CONTENT, CPNT, $colorProfile)};
    color: ${({ $colorProfile }) => themeUtil(Prop.CONTENT, CPNT, $colorProfile)};
  }

  ${Breakpoints.mobile(css`
    grid-column: span ${({ $index, $numItems }) => (($index > 4) ? 4 / ($numItems - 4) : 1)};
    align-items: center;

    h3 {
      ${() => getFontStyles(FontFamily.BASE_FONT, FontSize.REG40, undefined, undefined)};
      margin-bottom: 10px;
    }

    p {
      ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.LRG30, undefined, undefined)};
    }
  `)}
`;

export const ItemIcon = styled(Icon)`
  width: 35px;
  height: 35px;

  ${Breakpoints.mobile(css`
    width: 28px;
    height: 28px;
  `)}
`;

export const ItemAlignmentWrapper = styled.div``;

export const ReportContentWrapper = styled.div`
  ${({ $isParentNavFeed }) => ($isParentNavFeed) && clamp(8)};
  margin-bottom: 15px;
`;
