import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';

import {
  ComponentDrawer, Link, MainNav,
} from '@powdr/components';
import {
  ThemeProps as Prop, Components,
  Breakpoints,
  AbsolutePositions,
  FontFamily,
  FontSize,
  NavigationPromoStyleOptions,
} from '@powdr/constants';
import { componentColor, getFontStyles } from '@powdr/utils';

const CPNT = Components.NAVIGATION_DRAWER;

export const StyledNavigationDrawer = styled(ComponentDrawer)`

  .drawer-close-button svg {
    fill: ${() => componentColor(Prop.ICON, CPNT)};
    color: ${() => componentColor(Prop.ICON, CPNT)};
  }
`;

export const NavDrawerBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 100px;

  ${Breakpoints.mobile(css`
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 100vh;
    gap: 25px;
    padding-bottom: 25px;
  `)}
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 25px;

  .header-logo svg {
    width: 100%;
  }
`;

export const Navigation = styled(MainNav)`
  ${Breakpoints.desktopOnly(css`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    top: 200px;
  `)}

  ${Breakpoints.mobile(css`
    display: block;
    width: 100%;
  `)}
`;

export const DrawerBodyInner = styled.div`
  display: flex;
  flex-direction: column;

  ${Breakpoints.mobile(css`
    gap: 15px;
    flex-direction: column-reverse;
  `)}
`;

export const Promotions = styled.div`
  margin: 0 auto;
`;

export const PromoListItems = styled.ul`
  display: flex;
  position: relative;
  gap: 30px;
  align-items: center;
  justify-content: center;

  ${Breakpoints.desktopOnly(css`
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    transform: ${({ $shift }) => (($shift === AbsolutePositions.LEFT || $shift === AbsolutePositions.RIGHT)
    ? `translateX(${($shift === AbsolutePositions.LEFT) ? '-50%' : '50%'})`
    : 'translateX(0);')};

    ${({ $shift }) => ($shift === AbsolutePositions.TOP) && `
      pointer-events: none;
      opacity: 0;
    `}
  `)}

  ${Breakpoints.mobile(css`
    flex-direction: column;
  `)}
`;

export const PromoListItem = styled.li``;

export const PromoDisc = styled(Link)`
  display: grid;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  height: 150px;
  width: 150px;
  background: center / contain no-repeat url(${({ $bgImage }) => $bgImage});
  ${() => getFontStyles(FontFamily.SECONDARY_FONT, FontSize.REG40, undefined, undefined)};
  line-height: 1.1;
  font-weight: bold;
`;

export const DiscContent = styled.div`
  grid-area: 1/1;
  z-index: -1;
  padding: 15px;
  color: ${() => componentColor(Prop.NAV_PROMO_ITEM_TEXT, CPNT, Prop.HEADER)};
  text-shadow: 2px 2px 10px #000;
`;

export const DiscBackgroundImage = styled(GatsbyImage)`
  grid-area: 1/1;
  height: 100%;
  width: 100%;
  z-index: -1;
  border-radius: ${({ $promoStyle }) => (($promoStyle === NavigationPromoStyleOptions.CIRCULAR) ? '50%' : 0)};
`;
