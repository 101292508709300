import styled, { css } from 'styled-components';

import { Icon } from '@powdr/components';
import {
  ThemeProps as Prop, Breakpoints, Components, FontFamily, FontSize,
} from '@powdr/constants';
import { getDorComponentColor, getFontStyles, themeUtil } from '@powdr/utils';

const CPNT = Components.DOR;

export const StyledDorForecastItem = styled.div`
  display: flex;
  flex-direction: column;
  ${({ $isFitToContent }) => (($isFitToContent)
    ? 'width: fit-content; margin: 0 auto;'
    : 'width: 100%; min-width: 200px;')}
  gap: 30px;
`;

export const Section = styled.div``;

export const ForecastIcon = styled(Icon)`
  svg {
    fill: ${({ isParentNavFeed, colorProfile }) => getDorComponentColor(isParentNavFeed, colorProfile, Prop.CONTENT, CPNT)};
    color: ${({ isParentNavFeed, colorProfile }) => getDorComponentColor(isParentNavFeed, colorProfile, Prop.CONTENT, CPNT)};
  }
`;

export const TemperatureValues = styled.div`
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.LRG50, undefined, undefined)};
  line-height: 1;
  white-space: nowrap;
`;

export const SnowfallValues = styled.div`
${() => getFontStyles(FontFamily.BASE_FONT, FontSize.REG50, undefined, undefined)};
  line-height: 1.1;
`;

export const Date = styled.h3`
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.REG50, undefined, undefined)};
  line-height: 1.1;
`;

export const ForecastDesc = styled.p`
  ${() => getFontStyles(FontFamily.BASE_FONT, FontSize.REG40, undefined, undefined)};
  line-height: normal;
`;
