import PropTypes from 'prop-types';
import React from 'react';

import { HtmlContentLoader, WysiwygStyleWrapper } from '@powdr/components';
import { Alignment } from '@powdr/constants';

import { StyledUiHtml, StyledIcon } from './styles';

export const UiHtml = ({
  html,
  isHideComponent,
  iconName,
  parentColorProfile,
  alignment,
}) => (
  (!isHideComponent) && (
  <StyledUiHtml className="ui-html" colorProfile={parentColorProfile} $alignment={alignment}>
    {iconName && (<StyledIcon name={iconName} width="50" height="50" />)}
    <WysiwygStyleWrapper>
      <HtmlContentLoader html={html?.value} allowRerender />
    </WysiwygStyleWrapper>
  </StyledUiHtml>
  )
);

UiHtml.propTypes = {
  html: PropTypes.shape({
    value: PropTypes.string,
  }).isRequired,
  isHideComponent: PropTypes.bool,
  iconName: PropTypes.string,
  parentColorProfile: PropTypes.string,
  alignment: PropTypes.string,
};

UiHtml.defaultProps = {
  isHideComponent: false,
  iconName: '',
  parentColorProfile: null,
  alignment: Alignment.LEFT,
};
