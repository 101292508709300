import styled, { css } from 'styled-components';

import {
  Button, ComponentDrawer, Link,
} from '@powdr/components';
import {
  ThemeProps as Prop, Breakpoints, Components, FontSize,
  FontFamily,
  VerticalPosition,
} from '@powdr/constants';
import { componentColor, getFontStyles, themeUtil } from '@powdr/utils';

const CPNT = Components.DOR_DRAWER;

export const StyledDorDrawer = styled(ComponentDrawer)`
  padding: 50px 100px;

  ${Breakpoints.mobile(css`
    padding: 0 0 25px;
  `)}
`;

export const LogoWrapper = styled.div`
  display: none;

  ${Breakpoints.mobile(css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 25px 0;
  `)}
`;

export const DorDrawerBody = styled.div`
  height: 100%;
  overflow-y: scroll;

  ${Breakpoints.mobile(css`
    height: auto;
    overflow-x: hidden;
    max-height: 100vh;
    padding: 75px 25px 25px;
  `)}
`;

export const DorComponentGrid = styled.div`
  ${Breakpoints.desktopOnly(css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 25px 8%;
    grid-template-rows: auto 1fr 1fr;
    grid-template-areas:
      "promotions promotions"
      "component1 component2"
      "component3 component4";
    width: 100%;
    height: 100%;

    ${({ $promoPlacement }) => (($promoPlacement === VerticalPosition.TOP) ? `
      grid-template-rows: auto 1fr 1fr;
      grid-template-areas:
        "promotions promotions"
        "component1 component2"
        "component3 component4";
    ` : `
      grid-template-rows: 1fr 1fr auto;
      grid-template-areas:
        "component1 component2"
        "component3 component4"
        "promotions promotions";
    `)}
  `)}

  ${Breakpoints.mobile(css`
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 25px;

    ${({ $promoPlacement }) => (($promoPlacement === VerticalPosition.TOP) ? `
      grid-template-auto-rows: auto;
      grid-template-areas:
        "promotions"
        "component1"
        "component2"
        "component3"
        "component4";
    ` : `
      grid-template-auto-rows: auto;
      grid-template-areas:
        "component1"
        "component2"
        "component3"
        "component4"
        "promotions";
    `)}
  `)}
`;

export const SectionWrapper = styled.div`
  border-top: solid 5px ${() => componentColor(Prop.HEADER, CPNT, Prop.BORDER)};
  padding-top: 30px;

  ${Breakpoints.desktopOnly(css`
    grid-area: ${({ $gridArea }) => $gridArea};
    min-height: 500px;
  `)}
`;

export const SectionTitle = styled.h2`
  color: ${() => componentColor(Prop.HEADER, CPNT)};
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, undefined, 1.75, undefined, CPNT, 'header')};
  margin-bottom: 8px;
`;

export const SectionLink = styled(Link)`
  display: block;
  ${() => getFontStyles(FontFamily.BASE_FONT, undefined, undefined, undefined, CPNT, 'link')};
  color: ${() => componentColor(Prop.LINK_TXT, CPNT)};
  transition: color 0.25s ease-in-out;
  margin-bottom: 50px;

  &:hover, &:focus {
    color: ${() => componentColor(Prop.LINK_TXT_HOVER, CPNT)};
  }

  ${Breakpoints.mobile(css`
    margin-bottom: 30px;
  `)}
`;

export const DorPromotionsList = styled.ul`
  display: grid;
  grid-area: promotions;
  grid-template-columns: repeat(${({ $numItems }) => $numItems}, 1fr);
  column-gap: 25px;

  ${Breakpoints.mobile(css`
    display: flex;
    flex-direction: column;
    row-gap: 25px;
  `)}
`;

export const PromotionItem = styled.li`
  height: 100%;
`;

export const Promotion = styled(Link)`
  display: flex;
  justify-content: space-between;
  gap: 15px;
  padding: 15px;
  background-color: ${({ $colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, $colorProfile)};
  height: 100%;

  &:focus {
    outline: 2px solid ${({ $colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, $colorProfile)};
    outline-offset: 0.2em;

    .promo-button {
      padding: 10px 20px;
      width: max-content;
      background-color: ${({ $colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, $colorProfile)};
    }
  }

  ${Breakpoints.mobile(css`
    flex-direction: column;
  `)}
`;

export const PromoSection = styled.div`
  display: flex;
  align-items: ${({ $vertAlign }) => (($vertAlign === VerticalPosition.MIDDLE) ? 'center' : 'flex-start')};
`;

export const PromoTitle = styled.h3`
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.MED10, undefined, undefined, CPNT, 'promo-header')};
  color: ${({ $colorProfile }) => themeUtil(Prop.HEADER, CPNT, $colorProfile)};
`;

export const PromoDesc = styled.p`
  ${() => getFontStyles(FontFamily.BASE_FONT, undefined, undefined, undefined, CPNT, 'promo-content')};
  line-height: 1.3;
  color: ${({ $colorProfile }) => themeUtil(Prop.CONTENT, CPNT, $colorProfile)};
`;

export const PromoButton = styled(Button)`
  padding: 10px 20px;
  width: max-content;
`;
