// eslint-disable-next-line import/no-unresolved
import { globalHistory } from '@reach/router';
import PropTypes from 'prop-types';
import React, {
  useContext, useEffect, useState, useCallback,
} from 'react';

import { RouteAction, AbsolutePositions } from '@powdr/constants';
import { AppContext } from '@powdr/context';
import { useSeason } from '@powdr/hooks';
import { isHideNavItem } from '@powdr/utils';
import { useStaticData } from '@powdr/web/src/hooks';

import { ChildNavDrawer, MainNavItem } from './components';
import {
  StyledMainNav,
  MainNavigationList,
} from './styles';

export const MainNav = ({
  className,
}) => {
  const { navigationDrawer: { navigation } } = useStaticData();
  const [openItem, setOpenItem] = useState(null);
  const [childItemData, setChildItemData] = useState(null);
  const [isChildDrawerOpen, setChildDrawerOpen] = useState(false);
  const [isSiteIndexActive, setSiteIndexActive] = useState(false);
  const { checkSeasonality } = useSeason();
  const {
    toggleBodyScroll,
    setShiftPromos,
    isNavigationDrawerOpen,
    setNavigationDrawerOpen,
    isMobile,
  } = useContext(AppContext);

  const itemToggleHandler = (idx) => setOpenItem((idx === openItem) ? null : idx);

  const siteIndexToggleHandler = () => {
    setSiteIndexActive(!isSiteIndexActive);

    if (isSiteIndexActive) setOpenItem(null);
  };

  const childDrawerHandler = (data) => {
    if (!data) {
      setChildDrawerOpen(false);
      setTimeout(() => {
        setChildItemData(data);
      }, 500);
    } else {
      setChildDrawerOpen(true);
      setChildItemData(data);
    }
  };

  const resetNavigationState = useCallback(() => {
    setOpenItem(null);
    setSiteIndexActive(false);
    childDrawerHandler(false);
    setNavigationDrawerOpen(false);
  }, [setNavigationDrawerOpen]);

  // remove noscroll class from body and close drawer on route change
  useEffect(() => globalHistory.listen(({ action }) => {
    if (action === RouteAction.PUSH) {
      toggleBodyScroll(false);
      resetNavigationState();
    }
  }), [toggleBodyScroll, setOpenItem, setNavigationDrawerOpen, resetNavigationState]);

  useEffect(() => {
    if (!isNavigationDrawerOpen) {
      resetNavigationState();
    }
  }, [isNavigationDrawerOpen, resetNavigationState]);

  useEffect(() => {
    if (isSiteIndexActive) {
      setShiftPromos(AbsolutePositions.TOP);
    } else if (openItem) {
      if (openItem === 1) {
        setShiftPromos(AbsolutePositions.RIGHT);
      } else if (openItem === 2) {
        setShiftPromos(AbsolutePositions.LEFT);
      } else {
        setShiftPromos(false);
      }
    } else {
      setShiftPromos(false);
    }
  }, [openItem, setShiftPromos, isSiteIndexActive]);

  if (!navigation) return null;

  return (
    <StyledMainNav
      id="main-nav-start"
      tabindex="0"
      aria-label="Start of Main Navigation"
      role="navigation"
      className={className}
    >
      <MainNavigationList>
        {/* Standard Navigation Items */}
        {navigation?.childPages
          .filter((i) => !isHideNavItem(i, checkSeasonality))
          .map((item, idx) => (
            <MainNavItem
              key={item.id}
              itemIndex={idx}
              navData={item}
              itemToggleFunc={itemToggleHandler}
              handleChildDrawer={childDrawerHandler}
              isOpen={idx === openItem || isSiteIndexActive}
              isShowIcon={!isSiteIndexActive}
            />
          ))}

        {/* Site Index Toggle */}
        <MainNavItem
          isSiteIndexToggle
          itemToggleFunc={siteIndexToggleHandler}
          isOpen={isSiteIndexActive}
        />
      </MainNavigationList>

      {/* Mobile Child Navigation Drawer */}
      {(isMobile) && (
        <ChildNavDrawer
          data={childItemData}
          isOpen={isChildDrawerOpen}
          closeDrawerFunc={childDrawerHandler}
        />
      )}

    </StyledMainNav>
  );
};

MainNav.propTypes = {
  className: PropTypes.string,
};

MainNav.defaultProps = {
  className: '',
};
