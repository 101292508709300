import styled, { css } from 'styled-components';

import { Pagination } from '@powdr/components';
import {
  Breakpoints, Components, FontFamily, FontSize, TextTransformOptions,
} from '@powdr/constants';
import { getFontStyles } from '@powdr/utils';

const CPNT = Components.GRID;

export const StyledGrid = styled.div`
  display: flex;
  gap: ${({ $columnGap, $rowGap }) => `${$rowGap}px ${$columnGap}%;`};
  margin: ${({ $topPadding, $bottomPadding }) => `${$topPadding || 0}px 0px ${$bottomPadding || 0}px;`};
  ${({ disableDesktopWrap }) => ((disableDesktopWrap) ? '' : 'flex-wrap: wrap;')}
  ${({ isCenterAlignUnevenRows }) => ((isCenterAlignUnevenRows) ? 'justify-content: center;' : '')}

  ${Breakpoints.mobile(css`
    flex-wrap: wrap;
  `)}
`;

export const GridPagination = styled(Pagination)``;

export const GridFilters = styled.div``;

export const FilterHeader = styled.h2`
  text-transform: uppercase;
  ${() => getFontStyles(FontFamily.SECONDARY_FONT, FontSize.MED40, undefined, TextTransformOptions.UPPERCASE)};
  margin-bottom: 10px;
`;

export const FilterToggles = styled.ul`
  display: flex;
  flex-wrap: wrap;

  li {
    margin-right: 25px;
    margin-bottom: 10px;
  }
`;
