import PropTypes from 'prop-types';
import React from 'react';

import {
  Header, Sidebar, NavigationDrawer, DorDrawer,
} from './components';

export const OverlayWrapper = ({
  levels,
  showStickyNav,
}) => (
  <>
    <Header levels={levels} showStickyNav={showStickyNav} />
    <Sidebar />
    <NavigationDrawer />
    <DorDrawer />
  </>
);

OverlayWrapper.propTypes = {
  levels: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  showStickyNav: PropTypes.bool.isRequired,
};

OverlayWrapper.defaultProps = {};
