import styled from 'styled-components';

import {
  ThemeProps as Prop, Components,
  AbsolutePositions,
  AlignmentShorthand,
} from '@powdr/constants';
import { componentColor, themeUtil } from '@powdr/utils';

const CPNT = Components.COMPONENT_DRAWER;

const fromLeft = {
  open: 'transform: translateX(0)',
  closed: 'transform: translateX(-100%)',
};

const fromRight = {
  open: 'transform: translateX(0)',
  closed: 'transform: translateX(100%)',
};

const fromBottom = {
  open: 'transform: translateY(0)',
  closed: 'transform: translateY(100%)',
};

const fromTop = {
  open: 'transform: translateY(0)',
  closed: 'transform: translateY(-100%)',
};

const transformMap = {
  [AbsolutePositions.TOP]: fromTop,
  [AbsolutePositions.BOTTOM]: fromBottom,
  [AbsolutePositions.LEFT]: fromLeft,
  [AbsolutePositions.RIGHT]: fromRight,
};

const getTransform = (slideInFrom) => transformMap[slideInFrom];

export const StyledComponentDrawer = styled.div`
  position: fixed;
  top: 0;
  z-index: 80; // places drawer on top of header
  height: 100%;
  width: 100vw;
  background-color: ${({ $componentTheme }) => componentColor(Prop.BACKGROUND, $componentTheme || CPNT)};
  transition: transform 0.5s ease-in-out;
  overflow: hidden;
  transform: translate3d(0,0,0);

  ${({ $isOpen, $slideInFrom }) => ($isOpen ? `
    ${getTransform($slideInFrom).open};
  ` : `
    ${getTransform($slideInFrom).closed};
  `)}
`;

export const DrawerCloseButton = styled.button`
  position: absolute;
  top: 0;
  ${({ $position }) => (($position === AlignmentShorthand.TOP_RIGHT) ? 'right' : 'left')}: 0;
  padding: 25px;
  background-color: ${({ $componentTheme }) => themeUtil(Prop.BACKGROUND, $componentTheme || CPNT)};
  z-index: 80;

  svg {
    fill: ${({ $componentTheme }) => themeUtil(Prop.ICON, $componentTheme || CPNT)};
    color: ${({ $componentTheme }) => themeUtil(Prop.ICON, $componentTheme || CPNT)};
  }
`;
