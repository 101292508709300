import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';

import { UiEmbed, Link, PriceRange } from '@powdr/components';
import {
  BlockTypes, FontFamily, FontSize, ThemeProps as Prop, TextTransformOptions,
} from '@powdr/constants';
import { ContentBlockType } from '@powdr/model';
import {
  themeUtil, formatDate, phoneNumberPrettify, getFontStyles,
} from '@powdr/utils';

const CPNT = BlockTypes.BASIC;

// styled components
const ContentBlockBasic = styled.div`
  position: relative;

  .link-icon {
    margin-left: 5px;
    margin-top: -3px;
    height: 1em;
    width: 1em;

    svg {
      fill: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT, CPNT, colorProfile)};
      color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT, CPNT, colorProfile)};
    }
  }

  .image-container {
    position: relative;
  }

  .cb-title {
    margin-bottom: 15px;
  }

  .cb-copy {
    margin-bottom: 15px;
  }

  .cb-basic-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px 0;
    color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT, CPNT, colorProfile)};
  }

  &:hover {
    .cb-basic-button {
      color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT_HOVER, CPNT, colorProfile)};
    }

    .link-icon {
      svg {
        fill: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT_HOVER, CPNT, colorProfile)};
        color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT_HOVER, CPNT, colorProfile)};
      }
    }
  }

  small {
    ${() => getFontStyles(FontFamily.BASE_FONT, FontSize.REG30, undefined, TextTransformOptions.NO_TRANSFORM)};
    margin-bottom: 10px;
  }
`;

const ImageContainer = styled(Link)`
  display: block;
  height: 100%;
`;

const StyledImage = styled(GatsbyImage)`
  margin-bottom: 15px;
`;

const Image = styled.img`
  width: 100%;
  margin-bottom: 15px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
`;

const TagContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 50px;
  margin-bottom: 10px;
`;

const AdditionalInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 50px;
  line-height: 1.3;
  padding: 30px 0;

  a {
    transition: color .25s ease-out;
    color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT, CPNT, colorProfile)};

    &:hover {
      color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT_HOVER, CPNT, colorProfile)};
    }
  }
`;

const AILeft = styled.div``;

const CustomPriceRange = styled(PriceRange)`
  font-weight: bold;
`;

const AIRight = styled.div`
  text-align: right;
`;

const AIRightTop = styled.div`
  font-weight: bold;
  padding-bottom: 25px;
`;

const AIRightBottom = styled.div``;

const Date = styled.p`
  text-align: left;
  line-height: 1.1;
  font-weight: bold;
`;

const Tags = styled.p`
  text-align: right;
  font-weight: bold;
  line-height: 1.1;
  text-transform: capitalize;
`;

export const Basic = ({
  title, copy, blockType, colorProfile, image, videoId, isStaticImageLink, ariaLabel,
  buttonEnabled, buttonLink, buttonText, buttonTarget, buttonClick, date, endDate,
  altButtonEnabled, altButtonText, altButtonLink, altButtonTarget,
  formattedContent, tags, isHideTags,
  hours, phoneNumber, phoneNumberDetails, location, locationDetails, priceRange,
}) => (
  <ContentBlockBasic colorProfile={colorProfile} className={`content-block-${blockType}`}>
    {(date || tags) && (
      <TagContainer>
        {(date) && <Date>{`${formatDate(date).month.num}/${formatDate(date).day.num}/${formatDate(date).year}${(endDate && date !== endDate) ? ` - ${formatDate(endDate).month.num}/${formatDate(endDate).day.num}/${formatDate(endDate).year}` : ''}`}</Date>}
        {(tags) && <Tags>{tags}</Tags>}
      </TagContainer>
    )}
    {(image) && (
      <ImageContainer
        onClick={() => buttonClick()}
        aria-label={ariaLabel}
        role="button"
        href={buttonLink}
        target={buttonTarget}
      >
        {(!videoId && !isStaticImageLink) && <StyledImage image={image.src} alt={image?.altText} />}
        {(!videoId && isStaticImageLink) && <Image src={image.src} alt={image?.altText} />}
        {(videoId) && (
          <div className="image-container">
            <UiEmbed videoId={videoId} />
            {(!isStaticImageLink) && <StyledImage image={image.src} alt={image?.altText} />}
            {(isStaticImageLink) && <Image src={image.src} alt={image?.altText} />}
          </div>
        )}
      </ImageContainer>
    )}
    {(title) && <h3 className="cb-title">{title}</h3>}
    {(hours || phoneNumber || location) && (
      <AdditionalInfoContainer $colorProfile={colorProfile}>
        <AILeft>
          {(priceRange?.length > 0) && <CustomPriceRange max={4} value={priceRange.length || 1} character="$" />}
          <div>{ReactHtmlParser(hours)}</div>
        </AILeft>
        <AIRight>
          <AIRightTop>
            <p>{location}</p>
            <p>{locationDetails}</p>
          </AIRightTop>
          <AIRightBottom>
            <p><a href={`tel:${phoneNumber}`}>{phoneNumberPrettify(phoneNumber)}</a></p>
            <p>{phoneNumberDetails}</p>
          </AIRightBottom>
        </AIRight>
      </AdditionalInfoContainer>
    )}
    {(copy) && <p className="cb-copy">{ReactHtmlParser(copy)}</p>}
    {(formattedContent) && <div className="cb-formatted-content">{ReactHtmlParser(formattedContent)}</div>}
    <ButtonWrapper>
      {(buttonText && buttonEnabled) && (
        <Link
          className="button with-background uppercase"
          onClick={() => buttonClick()}
          aria-label={ariaLabel}
          role="button"
          href={buttonLink}
          target={buttonTarget}
        >
          {buttonText}
        </Link>
      )}
      {(altButtonEnabled) && (
        <Link
          className="button with-background uppercase"
          onClick={() => buttonClick()}
          aria-label={ariaLabel}
          role="button"
          href={altButtonLink}
          target={altButtonTarget || buttonTarget}
        >
          {altButtonText}
        </Link>
      )}
    </ButtonWrapper>
  </ContentBlockBasic>
);

Basic.propTypes = PropTypes.objectOf(ContentBlockType).isRequired;
