import PropTypes from 'prop-types';
import React, {
  useEffect,
  useRef,
  useState,
  useContext,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Loader } from '@powdr/components';
import {
  DorLiftsTrailsDrawer,
  DorLiftsTrailsMain,
} from '@powdr/components/dor-lifts-trails/index';
import {
  LiftsTrails,
  LiftColumn,
  TrailColumn,
  SectorConditions,
  LiftConditions,
  DrawrGeneral,
  ContentFilter,
  StatusProps,
  FilterDefaultState,
  StatusTrailSubTypes,
} from '@powdr/constants';
import { AppContext } from '@powdr/context';
import { getLiftTrailReport } from '@powdr/stores/actions/lift-trail-report-action';
import { convertCmsSetToBools, findStatusTrailSubType } from '@powdr/utils';
import { useStaticData } from '@powdr/web/src/hooks';

import {
  StyledDorLiftsTrails,
  StyledLayoutWrapper,
  StyledDorLoaderWrapper,
  StyledDorErrorMessage,
} from './styles';

export const DorLiftsTrails = ({
  parentColorProfile,
  hideFilterContent,
  hideFilterDrawer,
  hideLiftsTrails,
  hideLiftColDesktop,
  hideTrailColDesktop,
  hideTrailColMobile,
  hideTrailFeature,
  hideTrailGroomedStatus,
  hideTrailDifficulty,
  hideSectorConditions,
  hideLiftColMobile,
  hideLiftsConditions,
  hideGeneralDrawr,
  hideTrailFeatureDiff,
  hideTrailFeatureSize,
  hideTrailStatus,
  hideLiftStatus,
  filterDefaultState,
  panelsDefaultState,
  overrideSeason,
  parentComponentSeason,
}) => {
  const liftTrailContentRef = useRef();
  const {
    isMobile,
    secondLevelNavHeight,
    themeConfig,
  } = useContext(AppContext);

  const { property } = useStaticData();
  const settings = {
    property,
    isMobile,
    isDesktop: !isMobile,
    contentRef: liftTrailContentRef,
    hideLiftCol: isMobile ? hideLiftColMobile : hideLiftColDesktop,
    hideTrailCol: isMobile ? hideTrailColMobile : hideTrailColDesktop,
    liftColumn: convertCmsSetToBools(isMobile
      ? hideLiftColMobile
      : hideLiftColDesktop, LiftColumn),
    trailColumn: convertCmsSetToBools(isMobile
      ? hideTrailColMobile
      : hideTrailColDesktop, TrailColumn),
    sectorConditions: convertCmsSetToBools(hideSectorConditions, SectorConditions),
    filterContent: convertCmsSetToBools(hideFilterContent, ContentFilter),
    flterOutTrailTypes: findStatusTrailSubType(hideLiftsTrails, StatusTrailSubTypes),
    liftsTrails: convertCmsSetToBools(hideLiftsTrails, LiftsTrails),
    liftsConditions: convertCmsSetToBools(hideLiftsConditions, LiftConditions),
    generalDrawr: convertCmsSetToBools(hideGeneralDrawr, DrawrGeneral),
    season: overrideSeason || parentComponentSeason || StatusProps.SEASON.ALL_SEASONS,
    tableThStickyOffset: themeConfig.layout.headerHeight + secondLevelNavHeight + 70,
    drawerStickyOffset: themeConfig.layout.headerHeight + secondLevelNavHeight + 10,
    hideFilterDrawer,
    hideLiftsTrails,
    hideTrailFeature,
    hideTrailGroomedStatus,
    hideTrailDifficulty,
    hideSectorConditions,
    hideLiftsConditions,
    hideGeneralDrawr,
    hideTrailFeatureDiff,
    hideTrailFeatureSize,
    hideTrailStatus,
    hideLiftStatus,
    filterDefaultState,
    panelsDefaultState,
    overrideSeason,
    parentComponentSeason,
  };

  const theme = {
    parentColorProfile,
    sectorliftHeader: {
      class: 'conditions',
    },
    sectorTrailsHeader: {
      class: null,
    },
  };

  const dispatch = useDispatch();

  const useLiftTrailReportData = useSelector((state) => state.liftTrailReport);

  const {
    error: liftTrailReportError,
    data: liftTrailReportData,
  } = useLiftTrailReportData;

  const [data, setData] = useState({
    sectors: null,
    trails: null,
    lifts: null,
  });

  const [filteredTrailIds, setFilteredTrailIds] = useState([]);
  const [filteredLiftIds, setFilteredLiftIds] = useState([]);

  const getFilteredLiftData = (currentFilters) => {
    const { lifts } = data;

    const filterResults = (key) => lifts
      ?.filter((trail) => currentFilters
        .includes(trail[key]?.id))
      .map((f) => f.id) || [];

    const statusResults = filterResults('status');

    setFilteredLiftIds(statusResults);
  };

  const getFilteredTrailData = (currentFilters) => {
    const { trails } = data;

    const filterResults = (key) => trails
      ?.filter((trail) => currentFilters
        .includes(trail[key]?.id))
      .map((f) => f.id) || [];

    const statusResults = filterResults('status');
    const difficultyResults = filterResults('difficulty');
    const groomedResults = filterResults('groomed');

    const featureDifficultyResults = trails
      ?.filter((trail) => trail
        ?.featureDifficulty
        ?.some((f) => currentFilters
          .includes(f.id)))
      ?.map((f) => f.id) || [];

    const featureResults = trails
      ?.filter((trail) => trail
        ?.feature?.some((f) => currentFilters
          .includes(f?.id)))
      .map((f) => f?.id) || [];

    const featureSizeResults = trails
      ?.filter((trail) => trail
        ?.featureSize
        ?.some((f) => currentFilters
          .includes(f.id)))
      .map((f) => f.id) || [];

    const combinedResults = [
      ...new Set([
        ...statusResults,
        ...difficultyResults,
        ...groomedResults,
        ...featureDifficultyResults,
        ...featureResults,
        ...featureSizeResults,
      ]),
    ];

    setFilteredTrailIds(combinedResults);
  };

  const handleOnCheckboxChange = (currentFilters, options = { type: 'trail' }) => {
    if (options.type === 'trail') {
      getFilteredTrailData(currentFilters);
    }
    if (options.type === 'lift') {
      getFilteredLiftData(currentFilters);
    }
  };

  // ERRORS START
  if (liftTrailReportError) {
    const message = `DOR Lift Trail Report Error API Failed: ${liftTrailReportError?.message ?? ''}`;
    console.error(message);
    return <StyledDorErrorMessage message={message} />;
  }
  // ERRORS END

  useEffect(() => {
    dispatch(getLiftTrailReport());
  }, [dispatch]);

  useEffect(() => {
    if (liftTrailReportData) {
      setData({
        sectors: liftTrailReportData?.data?.sector,
        trails: liftTrailReportData?.data?.trail,
        lifts: liftTrailReportData?.data?.lift,
      });
    }
  }, [liftTrailReportData]);

  return (

    (!data.sectors && !data.trails && !data.lifts) ? (
      <StyledDorLoaderWrapper>

        <Loader
          className="icon"
          classAnimationEffect="loader-animated spin infinite"
          iconName="dor-sunny"
          size="40"
        />

      </StyledDorLoaderWrapper>

    ) : (

      <StyledDorLiftsTrails colorProfile={theme.parentColorProfile}>

        <StyledLayoutWrapper>

          <DorLiftsTrailsDrawer
            data={data}
            settings={settings}
            theme={theme}
            onFilterChange={handleOnCheckboxChange}
          />

          <DorLiftsTrailsMain
            ref={liftTrailContentRef}
            data={data}
            settings={settings}
            theme={theme}
            onFilterChange={handleOnCheckboxChange}
            filters={{
              trailIds: filteredTrailIds,
              liftIds: filteredLiftIds,
            }}
          />

        </StyledLayoutWrapper>

      </StyledDorLiftsTrails>

    ));
};

DorLiftsTrails.propTypes = {
  parentColorProfile: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  hideFilterContent: PropTypes.arrayOf(PropTypes.string),
  hideFilterDrawer: PropTypes.arrayOf(PropTypes.string),
  hideLiftsTrails: PropTypes.arrayOf(PropTypes.string),
  hideLiftColDesktop: PropTypes.arrayOf(PropTypes.string),
  hideTrailColDesktop: PropTypes.arrayOf(PropTypes.string),
  hideTrailColMobile: PropTypes.arrayOf(PropTypes.string),
  hideTrailFeature: PropTypes.arrayOf(PropTypes.string),
  hideTrailFeatureSize: PropTypes.arrayOf(PropTypes.string),
  hideTrailGroomedStatus: PropTypes.arrayOf(PropTypes.string),
  hideTrailDifficulty: PropTypes.arrayOf(PropTypes.string),
  hideSectorConditions: PropTypes.arrayOf(PropTypes.string),
  hideLiftColMobile: PropTypes.arrayOf(PropTypes.string),
  hideLiftsConditions: PropTypes.arrayOf(PropTypes.string),
  hideGeneralDrawr: PropTypes.arrayOf(PropTypes.string),
  hideTrailFeatureDiff: PropTypes.arrayOf(PropTypes.string),
  hideTrailStatus: PropTypes.arrayOf(PropTypes.string),
  hideLiftStatus: PropTypes.arrayOf(PropTypes.string),
  filterDefaultState: PropTypes.string,
  panelsDefaultState: PropTypes.string,
  overrideSeason: PropTypes.string,
  parentComponentSeason: PropTypes.string,
};

DorLiftsTrails.defaultProps = {
  parentColorProfile: null,
  hideFilterContent: [],
  hideFilterDrawer: [],
  hideLiftsTrails: [],
  hideLiftColDesktop: [],
  hideTrailColDesktop: [],
  hideTrailColMobile: [],
  hideTrailFeature: [],
  hideTrailFeatureSize: [],
  hideTrailGroomedStatus: [],
  hideTrailDifficulty: [],
  hideSectorConditions: [],
  hideLiftColMobile: [],
  hideLiftsConditions: [],
  hideGeneralDrawr: [],
  hideTrailFeatureDiff: [],
  hideTrailStatus: [],
  hideLiftStatus: [],
  filterDefaultState: FilterDefaultState.CLOSED,
  panelsDefaultState: FilterDefaultState.CLOSED,
  overrideSeason: null,
  parentComponentSeason: null,
};
