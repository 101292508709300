import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Icon } from '@powdr/components';

import { StyledDorFilter } from './styles';

export const DorCheckboxGroup = ({
  title,
  id,
  layout = 'row',
  className,
  initialChecked = [],
  checkInputsFields = [],
  inputLabelTitle = '',
  onFiltersChange,
}) => {
  const [checked, setChecked] = useState(initialChecked);

  const handleCheckboxChange = (e) => {
    const { target } = e;
    const { value, checked: isChecked } = target;

    let updatedChecked = [];

    if (isChecked) {
      updatedChecked = [...checked, value];
    } else {
      updatedChecked = checked.filter((f) => f !== value);
    }

    setChecked(updatedChecked);
    onFiltersChange(updatedChecked);
  };

  return (
    <StyledDorFilter id={id} className={`layout-${layout}`}>
      {title && <h4>{title}</h4>}
      <form className={classNames('form', className)}>
        <ul className="checkboxes">
          {checkInputsFields.map((field) => (
            <li key={field.id} className="checkbox">
              <label htmlFor={field.id} className="input-checkbox">
                <input
                  id={field.id}
                  name={field.id}
                  aria-label={`${inputLabelTitle}: ${field.id} (space to toggle checkbox)`}
                  onChange={handleCheckboxChange}
                  type="checkbox"
                  value={field.id}
                  tabIndex={0}
                  checked={checked.includes(field.id)}
                />
                {field.icons ? (
                  field.icons.map((i) => (
                    <Icon
                      key={i.icon}
                      className={`icon ${i.css}`}
                      name={i.icon}
                      width="23"
                      height="23"
                    />
                  ))
                ) : (
                  <Icon
                    className={`icon ${field.css}`}
                    name={field.icon}
                    width="23"
                    height="23"
                  />
                )}
                {field.label}
              </label>
            </li>
          ))}
        </ul>
      </form>
    </StyledDorFilter>
  );
};

DorCheckboxGroup.propTypes = {
  title: PropTypes.string,
  inputLabelTitle: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  checkInputsFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      enabled: PropTypes.bool,
      icon: PropTypes.string,
      css: PropTypes.string,
      label: PropTypes.string,
      icons: PropTypes.arrayOf(
        PropTypes.shape({
          css: PropTypes.string,
          icon: PropTypes.string,
        }),
      ),
    }),
  ),
  initialChecked: PropTypes.arrayOf(PropTypes.string),
  layout: PropTypes.oneOf(['row', 'column']),
  onFiltersChange: PropTypes.func,
};

DorCheckboxGroup.defaultProps = {
  title: null,
  inputLabelTitle: '',
  id: null,
  className: null,
  checkInputsFields: [],
  initialChecked: [],
  layout: 'row',
  onFiltersChange: () => { },
};
