import { Season } from './constants';

export const MountainOverviewGroupsTypes = {
  LIFTS_TRAILS: 'Lifts & Trails',
  SERVICES_AMENITIES: 'Services & Amenities',
  TRAIL_REPORT: 'Trail Report',
  WEATHER: 'Weather',
};

export const CondSectorTypes = {
  ID: 'id',
  NAME: 'name',
  TOTAL: 'total',
  OPEN: 'open',
  LENGTH: 'length',
  AREA: 'area',
};

export const CondSectorLengthLabels = {
  SKIABLE_MILES: 'Skiable Miles',
  RIDABLE_MILES: 'Ridable Miles',
  MILES: 'Miles',
  SKIABLE_ACRES: 'Skiable Acres',
  RIDABLE_ACRES: 'Ridable Acres',
  ACRES: 'Acres',
  SKIABLE_KILOMETERS: 'Skiable Kilometers',
};

export const CondSectorLabels = {
  NORDIC: 'NORDIC',
  WEST_VILLAGE_BASE_AREA: 'WEST VILLAGE BASE AREA',
  TOP_OF_SUNRISE_LIFT: 'TOP OF SUNRISE LIFT',
};

export const LiftTrailReportsStats = [
  {
    label: 'Open Trails',
    data: ['trail', 'alpineTrail'],
    alias: ['trail', 'alpineTrail', 'Open Trails'],
  },
  {
    label: 'Open Lifts',
    data: 'liftReport',
    alias: ['Open Lifts'],
  },
  {
    label: 'Cross-Country',
    data: 'crossCountryTrail',
    alias: ['crossCountryTrail', 'crosscountry', 'Cross-Country', 'Cross Country'],
  },
  {
    label: 'Mountain Bike',
    data: 'mountainBike',
    alias: ['mountainBike', 'Mountain Bike', 'mountainbike'],
  },
  {
    label: 'Hiking Trails',
    data: 'hikingTrail',
    alias: ['hikingTrail', 'hikingtrail'],
  },
  {
    label: 'Uphill Trails',
    data: 'uphillTrail',
    alias: ['uphillTrail', 'Uphill Trails'],
  },
  {
    label: 'Mountain Bike & Hiking Trails',
    data: 'hikingTrail',
    alias: ['hikingTrail', 'hikingtrail'],
  },
  {
    label: 'Mountain Bike & Hiking Trails',
    data: 'mountainBike',
    alias: 'mountainBike',
  },
  {
    label: 'Terrain Park',
    data: ['terrainPark'],
    alias: ['terrainPark'],
  },
  {
    label: 'Miles of Skiing',
    data: ['trail', 'alpineTrail'],
    alias: ['Miles of Skiing'],
    type: 'length',
  },
  {
    label: 'Acres of Skiing',
    data: ['trail', 'alpineTrail'],
    alias: ['Acres of Skiing'],
    type: 'area',
  },
];

export const HideOptions = {
  TITLE: 'Title',
  DATE: 'date',
  HTML: 'html',
  COPY: 'copy',
};

export const LiftsTrails = {
  LIFT: 'lift',
  TRAIL: 'trail',
  CROSS_COUNTRY: 'crosscountry',
  HIKING: 'hikingtrail',
  MOUNTAIN_BIKE: 'mountainbike',
  ALPINE: 'alpinetrail',
  TERRAIN_PARK: 'terrainpark',
};

export const TrailStatus = {
  OPENING: 'opening',
  CLOSED: 'closed',
  ON_HOLD: 'onhold',
  EXPECTED: 'expected',
};

export const LiftColumn = {
  NAME: 'name',
  STATUS: 'status',
  TYPE: 'type',
  HOURS: 'hours',
  VERTICAL: 'vertical',
  NOTES: 'notes',
  WAIT_TIME: 'waitTime',
};

export const TrailColumn = {
  NAME: 'name',
  STATUS: 'status',
  DIFFICULTY: 'difficulty',
  FEATURE_DIFFICULTY: 'featureDifficulty',
  FEATURE_SIZE: 'featureSize',
  GROOMED: 'groomed',
  FEATURES: 'features',
  NOTES: 'notes',
  DESCRIPTION: 'description',
  DETAILS: 'details',
};

export const TrailFeatureDifficulty = {
  TECHNICAL_TERRAIN: 'technical_terrain',
  FREERIDE_TERRAIN: 'freeride_terrain',
  JUMP_TRAIL: 'jump_trail',
  SKILL_START_PARK: 'skill_start_park',
  UPHILL_ROUTE: 'uphill_route',
};

export const TrailFeature = {
  CAPABLE: 'capable',
  RECENT: 'recent',
  GLADED: 'gladed',
  RACE: 'race',
};

export const TrailDifficulty = {
  GREEN: 'green',
  BLUE: 'blue',
  BLACK: 'black',
  BLACK_2: 'black2',
};

export const LiftStatus = {
  ON_HOLD: 'onHold',
  EXPECTED: 'expected',
};

export const TrailGroomedStatus = {
  RECENT: 'recent',
  PM: 'pm',
  AM: 'am',
};

export const LiftConditions = {
  LIFTS: 'lifts',
  MILES: 'miles',
  ACRES: 'acres',
};

export const SectorConditions = {
  TRAIL: 'trail',
  LIFT: 'lift',
  ACRES: 'acres',
  MILES: 'miles',
};

export const DrawrFilter = {
  STATUS: 'status',
  DIFFICULTY: 'difficulty',
  FEATURE_SIZE: 'featureSize',
  FEATURES: 'features',
  FEATURE_DIFFICULTY: 'featureDifficulty',
  GROOMED: 'groomed',
};

export const TrailFeatureSize = {
  STATUS: 'status',
  DIFFICULTY: 'difficulty',
  FEATURE_SIZE: 'featureSize',
  FEATURES: 'features',
  FEATURE_DIFFICULTY: 'featureDifficulty',
  GROOMED: 'groomed',
};

export const DrawrGeneral = {
  FITLER_HEADER: 'fitlerHeader',
};

export const ContentFilter = {
  STATUS: 'status',
  DIFFICULTY: 'difficulty',
  GROOMED: 'groomed',
  FEATURE_SIZE: 'featureSize',
  FEATURES: 'features',
  FEATURE_DIFFICULTY: 'featureDifficulty',
  FEATURE_DIFFICULTY_LEGEND: 'featureDifficultyLegend',
  TAGS: 'tags',
};

export const Icons = {
  SUNNY: 'dor-sunny',
  MOSTLY_SUNNY: 'dor-mostlysunny',
  PARTLY_SUNNY: 'dor-partlysunny',
  CLOUDY: 'dor-cloudy',
  MOSTLY_CLOUDY: 'dor-mostlycloudy',
  WIND: 'dor-wind',
  SNOW: 'dor-snow',
  CHANCE_SNOW: 'dor-chancesnow',
  RAIN: 'dor-rain',
  TSTORM: 'dor-tstorm',
  SLEAT: 'dor-sleat',
  HAZY: 'dor-hazy',
  UNKNOWN: 'dor-unknown',
};

export const IconLookup = {
  [Icons.SUNNY]: [
    'sunny',
    'hot',
    'skc',
    'nskc',
    '01d',
    '01n',
    '9', // openSnowV2api
    '14', // openSnowV2api
  ],
  [Icons.MOSTLY_SUNNY]: [
    'mostlysunny',
    'ovc',
    'few',
    'nfew',
    'sct',
    'nsct',
    '02d',
    '02n',
    '11', // openSnowV2api
  ],
  [Icons.PARTLY_SUNNY]: [
    'partlysunny',
    'bkn',
  ],
  [Icons.CLOUDY]: [
    'cloudy',
    '03d',
    '03n',
    '10', // openSnowV2api
  ],
  [Icons.MOSTLY_CLOUDY]: [
    'mostlycloudy',
    '04d',
    '04n',
    '12', // openSnowV2api
    '13', // openSnowV2api
  ],
  [Icons.WIND]: [
    'wind',
    'nwind',
    'wind_sct',
    'nwind_sct',
    'wind_few',
    'nwind_few',
    'wind_bkn',
    'nwind_bkn',
    'wind_ovc',
    'nwind_ovc',
    'wind_skc',
    'nwind_skc',
    '7', // openSnowV2api
  ],
  [Icons.SNOW]: [
    'snow',
    'nsnow',
    'snow_showers',
    'nsnow_showers',
    'rain_snow',
    'nrain_snow',
    'snow_fzra',
    'nsnow_fzra',
    'cold',
    'ncold',
    'blizzard',
    'nblizzard',
    '13d',
    '13n',
    '0', // openSnowV2api
    '1', // openSnowV2api
    '2', // openSnowV2api
    '8', // openSnowV2api
  ],
  [Icons.CHANCE_SNOW]: [
    'sn',
    'nsn',
    'snow-flake-cloud',
  ],
  [Icons.RAIN]: [
    'rain',
    'nrain',
    'rain_showers',
    'nrain_showers',
    'rain_frza',
    'nrain_frza',
    'rain_showers_hi',
    'nrain_showers_hi',
    'tsra',
    'ntsra',
    'tsra_sct',
    'ntsra_sct',
    'tsra_hi',
    'ntsra_hi',
    'tornado',
    'hurr_warn',
    'hurricane',
    'hurr_watch',
    'ts_warn',
    'ts_watch',
    'ts_hurr_warn',
    'tropical_storm',
    '9d',
    '9n',
    '10d',
    '10n',
    '3', // openSnowV2api
    '4', // openSnowV2api
    '5', // openSnowV2api
    '6', // openSnowV2api
  ],
  [Icons.TSTORM]: [
    'thunder_storm',
    '11d',
    '11n',
    '15', // openSnowV2api
    '16', // openSnowV2api
    '17', // openSnowV2api
  ],
  [Icons.SLEAT]: [
    'rain_sleat',
    'snow_sleet',
    'sleet',
    'fzra',
    'nfzra',
    '09d',
    '09n',
  ],
  [Icons.HAZY]: [
    'fog',
    'hazy',
    'haze',
    'dust',
    'smoke',
    '50d',
    '50n',
  ],
  [Icons.UNKNOWN]: [
    'unknown',
    '-1', // openSnowV2api
  ],
};

export const StatusStates = {
  OPENING: ['opening', 'open', 'opened_and_groomed'],
  ON_HOLD: ['on-hold'],
  EXPECTED: ['expected', 'expected_opening'],
  CLOSED: ['closed', 'close'],
  NOON_GROOMED: ['noon_groomed', 'close'],
  GROOMING: ['grooming'],
  RACE: ['race'], // old, used for DOR Display Boards
  GROOMED: ['grooming', 'noon_groomed', 'am_groomed', 'groomed_am'], // old, used for DOR Display Boards
};

export const StatusFeatures = {
  LAST_24H: 'last_24h',
  SNOWMAKING: 'snowmaking',
  WITH_SNOWMAKING: 'withSnowmaking',
  GLADED_TRAIL: 'gladedTrail',
  RACE: 'race',
  TERRAIN_PARKS: 'terrainParks',
  TECHNICAL_TERRAIN: 'technicalTerrain',
  FREERIDE_TERRAIN: 'freerideTerrain',
  JUMP_TRAIL: 'jumpTrail',
  UPHILL_ROUTE: 'uphillRoute',
  SKILL_START_PARK: 'skillStartPark',
  MOGULS: 'moguls',
};

export const StatusFeaturesIcons = {
  [StatusFeatures.LAST_24H]: {
    id: 'last_24h',
    icon: 'feature-snowmaking-24-hours',
    label: 'Snowmaking 24 hours',
  },
  [StatusFeatures.SNOWMAKING]: {
    id: 'featureSnowmaking',
    icon: 'feature-snowmaking-progress',
    label: 'Snowmaking progress',
  },
  [StatusFeatures.WITH_SNOWMAKING]: {
    id: 'withSnowmaking',
    icon: 'feature-snowmaking-capable',
    label: 'Snowmaking capable',
  },
  [StatusFeatures.GLADED_TRAIL]: {
    id: 'gladedTrail',
    icon: 'feature-gladed',
    label: 'Gladed',
  },
  [StatusFeatures.RACE]: {
    id: 'race',
    icon: 'feature-race',
    label: 'Race',
  },
  [StatusFeatures.TERRAIN_PARKS]: {
    id: 'terrainParks',
    icon: 'feature-terrain-park',
    label: 'Terrain Park',
  },
  [StatusFeatures.TECHNICAL_TERRAIN]: {
    id: 'technicalTerrain',
    icon: null,
    label: null,
  },
  [StatusFeatures.FREERIDE_TERRAIN]: {
    id: 'freerideTerrain',
    icon: null,
    label: null,
  },
  [StatusFeatures.JUMP_TRAIL]: {
    id: 'jumpTrail',
    icon: null,
    label: null,
  },
  [StatusFeatures.UPHILL_ROUTE]: {
    id: 'uphillRoute',
    icon: null,
    label: null,
  },
  [StatusFeatures.SKILL_START_PARK]: {
    id: 'skillStartPark',
    icon: null,
    label: null,
  },
};

export const StatusTypes = {
  TRAIL: 'trail',
  LIFT: 'lift',
  POI: 'poi',
};

export const CondTrailTypes = {
  ALPINE: 'alpineTrail',
  CROSS_COUNTRY: 'crossCountryTrail',
  MOUNTAIN_BIKE: 'mountainBike',
  HIKING: 'hikingTrail',
  TERRAIN_PARK: ['terrainParkTrail', 'terrainPark'],
};

export const StatusTrailSubTypes = {
  LIFT: 'lift',
  TRAIL: 'trail',
  MOUNTAIN_BIKE_TRAIL: [
    'mountain bike',
    'mountainBike',
    'mountainbike',
    'mountain_bike_trail',
  ],
  ALPINE_TRAIL: [
    'trail',
    'alpine trail',
    'alpineTrail',
    'alpinetrail',
    'alpine_trail',
  ],
  HIKING_TRAIL: [
    'hiking trail',
    'hikingtrail',
    'hikingTrail',
    'hiking_trail',
  ],
  UPHILL_TRAIL: [
    'Uphill trail',
    'uphill trail',
    'uphilltrail',
    'uphill_trail',
  ],
  TERRAIN_PARK_TRAIL: [
    'terrain park trail',
    'terrain park',
    'terrainParkTrail',
    'terrainparktrail',
    'terrainpark',
    'terrainPark',
    'terrain_park_trail',
  ],
  CROSS_COUNTRY_TRAIL: [
    'cross country trail',
    'crossCountryTrail',
    'crosscountry',
    'cross_country_trail',
  ],
  FAT_BIKE_TRAIL: [
    'fatbike',
    'Fat Bike',
    'fat_bike_trail',
  ],
  SNOW_SHOE: [
    'snowshoe',
    'Snowshoe',
    'Snow shoe',
  ],
};

export const StatusProps = {
  SUBTYPE: 'subtype',
  SECTOR: 'sector',
  OPENING_16H: 'opening_16h',
  GLOBAL_STATUS: 'global_status',
  GROOM_STATUS: 'groom_status',
  NOTES: 'notes',
  LENGTH: 'length',
  SEASON: Season,
  FREERIDE_TERRAIN: 'freeride_terrain',
  FEATURES: StatusFeatures,
  NAME: 'name',
  TYPE: 'type',
};

export const CondGroups = {
  TRAIL_REPORT: 'Trail Report',
};

export const NewFeatures = {
  SNOWMAKING_24_HOURS: 'snowmaking-24-hours',
  SNOWMAKING_PROGRESS: 'snowmaking-progress',
  SNOWMAKING_CAPABLE: 'snowmaking-capable',
  GLADED: 'gladed',
  RACE: 'race',
  TERRAIN_PARK: 'terrain-park',
};

export const Groomed = {
  RECENT: 'groomed-recent',
  AM: 'groomed-am',
  PM: 'groomed-pm',
};

export const DorFeatureSize = {
  L: 'L',
  M: 'M',
  M_L: 'M/L',
  S: 'S',
  S_M: 'S/M',
  S_M_L: 'S/M/L',
  SX_S: 'SX/S',
  XS: 'XS',
};

export const liftTableColumns = [
  {
    id: 'name',
    className: 'name',
    label: 'Lift Name',
  },
  {
    id: 'status',
    className: 'status',
    label: 'Status',
  },
  {
    id: 'type',
    className: 'type',
    label: 'Type',
  },
  {
    id: 'hours',
    className: 'hours',
    label: ' Hours',
  },
  {
    id: 'vertical',
    className: 'vertical',
    label: ' Vertical',
  },
  {
    id: 'waitTime',
    className: 'wait-time',
    label: 'Wait Time',
  },
  {
    id: 'notes',
    className: 'notes',
    label: 'Notes',
  },
];

export const trailTableColumns = [
  {
    id: 'label',
    className: 'name',
    label: 'Trail Name',
    hasIcon: false,
  },
  {
    id: 'status',
    className: 'status',
    label: 'Status',
    hasIcon: true,

  },
  {
    id: 'difficulty',
    className: 'difficulty',
    hasIcon: true,
    label: 'Difficulty',
  },
  {
    id: 'featureDifficulty',
    className: 'feature-difficulty',
    hasIcon: true,
    label: 'Difficulty',
  },
  {
    id: 'featureSize',
    className: 'feature-size',
    hasIcon: true,
    label: ' Feature Rating',
  },
  {
    id: 'groomed',
    className: 'groomed',
    hasIcon: true,
    label: ' Groomed',
  },
  {
    id: 'features',
    className: 'features',
    hasIcon: true,
    label: 'Features',
  },
  {
    id: 'notes',
    className: 'notes',
    hasIcon: false,
    label: 'Notes',
  },
  {
    id: 'description',
    className: 'description',
    label: 'Description',
    hasIcon: false,
  },
  {
    id: 'details',
    className: 'details',
    label: '',
    hasIcon: false,
  },
];

export const trailLegendSummer = [
  {
    id: 'freerideTerrain',
    label: 'Freeride',
    order: 0,
    modal: {
      url: '',
    },
    icons: [
      {
        id: 'easier',
        icon: 'difficulty-level-green-pill',
        css: '',
        label: 'Easiest',
        width: '44',
        height: '28',
      },
      {
        id: 'more_difficult',
        icon: 'difficulty-level-blue-pill',
        css: '',
        label: 'More Difficult',
        width: '44',
        height: '28',
      },
      {
        id: 'most_difficult',
        icon: 'difficulty-level-black-pill',
        css: '',
        label: 'Difficult',
        width: '44',
        height: '28',
      },
      {
        id: 'extremely_difficult',
        icon: 'difficulty-level-black-2-pill',
        css: '',
        label: 'Extremely Difficult',
        width: '44',
        height: '28',
      },
    ],
  },
  {
    id: 'technicalTerrain',
    label: 'Technical',
    order: 1,
    modal: {
      url: '',
    },
    icons: [
      {
        id: 'easier',
        icon: 'difficulty-level-green-technical',
        css: '',
        label: 'Easiest',
        width: '28',
        height: '28',
      },
      {
        id: 'more_difficult',
        icon: 'difficulty-level-blue-technical',
        css: '',
        label: 'More Difficult',
        width: '28',
        height: '28',
      },
      {
        id: 'most_difficult',
        icon: 'difficulty-level-black-technical',
        css: '',
        label: 'Difficult',
        width: '28',
        height: '28',
      },
      {
        id: 'extremely_difficult',
        icon: 'difficulty-level-black-2-technical',
        css: '',
        label: 'Extremely Difficult',
        width: '28',
        height: '28',
      },
    ],
  },
  {
    id: 'jumpTrail',
    label: 'Cross Country',
    order: 2,
    modal: {
      url: '',
    },
    icons: [
      {
        id: 'easier',
        icon: 'difficulty-level-green',
        css: '',
        label: 'Easiest',
        width: '26',
        height: '26',
      },
      {
        id: 'more_difficult',
        icon: 'difficulty-level-blue',
        css: '',
        label: 'More Difficult',
        width: '26',
        height: '26',
      },
      {
        id: 'most_difficult',
        icon: 'difficulty-level-black',
        css: '',
        label: 'Difficult',
        width: '26',
        height: '26',
      },
    ],
  },
  {
    id: 'hiking',
    label: 'Hikings',
    modal: {
      url: '',
    },
    order: 3,
    icons: [
      {
        id: 'easier',
        icon: 'difficulty-level-hiking',
        css: '',
        label: '',
        width: '28',
        height: '32',
      },
    ],
  },
  {
    id: 'skillStartPark',
    label: 'Proline',
    order: 4,
    modal: {
      url: '',
    },
    icons: [
      {
        id: 'extremely_difficult',
        icon: 'difficulty-level-proline',
        css: '',
        label: 'Professionals Only',
        width: '44',
        height: '28',
      },
    ],
  },
  {
    id: 'uphillRoute',
    label: 'Uphill',
    order: 5,
    modal: {
      url: '',
    },
    icons: [
      {
        id: 'easier',
        icon: 'difficulty-level-uphill',
        css: '',
        label: '',
        width: '28',
        height: '28',
      },
    ],
  },
];

export const trailFilter = [
  {
    id: 'status',
    label: 'status',
    order: 0,
    layout: 'content',
    season: 'all',
    modal: {
      url: '',
    },
    fields: [
      {
        id: 'opening',
        icon: 'status-opening',
        css: 'opening',
        label: 'Open',

      },
      {
        id: 'closed',
        icon: 'status-closed',
        css: 'closed',
        label: 'Closed',

      },
      {
        id: 'onhold',
        icon: 'status-on-hold',
        css: 'on-hold',
        label: 'On Hold',

      },
      {
        id: 'expected',
        icon: 'status-expected',
        css: 'expected',
        label: 'Expected',
      },
    ],
  },
  {
    id: 'difficulty',
    label: 'difficulty',
    order: 1,
    layout: 'drawr',
    season: 'all',
    modal: {
      url: '',
    },
    fields: [
      {
        id: 'green',
        icon: 'difficulty-level-green',
        css: 'level-green',
        label: 'Easiest',

      },
      {
        id: 'blue',
        icon: 'difficulty-level-blue',
        css: 'level-blue',
        label: 'More Difficult',

      },
      {
        id: 'black',
        icon: 'difficulty-level-black',
        css: 'difficulty-level-black',
        label: 'Most Difficult',

      },
      {
        id: 'black2',
        icon: 'difficulty-level-black-2',
        css: 'difficulty-level-black-2',
        label: 'Extreme',
      },
      {
        id: 'black3',
        icon: 'difficulty-level-black-3',
        css: 'difficulty-level-black-3',
        label: 'Extreme Terrain',
      },
    ],
  },
  {
    id: 'featureSize',
    label: 'Feature Size Rating',
    order: 2,
    layout: 'drawr',
    season: 'winter',
    modal: {
      url: '',
    },
    fields: [
      {
        id: 'XS',
        icon: 'feature-terrain-park_extra-small',
        css: 'extra-small',
        label: 'Extra Small',
      },
      {
        id: 'SX/S',
        icon: 'feature-terrain-park_extra-small-small',
        css: 'extra-small-small',
        label: 'Extra Small/Small',
      },
      {
        id: 'S',
        icon: 'feature-terrain-park_small',
        css: 'small',
        label: 'Small',
      },
      {
        id: 'S/M',
        icon: 'feature-terrain-park_small-medium',
        css: 'small-medium',
        label: 'Small/Medium',
      },
      {
        id: 'M',
        icon: 'feature-terrain-park_medium',
        css: 'medium',
        label: 'Medium',

      },
      {
        id: 'M/L',
        icon: 'feature-terrain-park_medium-large',
        css: 'medium-large',
        label: 'Medium/Large',

      },
      {
        id: 'L',
        icon: 'feature-terrain-park_large',
        css: 'large',
        label: 'Large',

      },
      {
        id: 'S/M/L',
        icon: 'feature-terrain-park_small-medium-large',
        css: 'small-medium-large',
        label: 'Small/Medium/Large',

      },
    ],
  },
  {
    id: 'featureDifficulty',
    label: 'Feature Difficulty',
    order: 3,
    layout: 'drawr',
    season: 'summer',
    modal: {
      url: '',
    },
    fields: [
      {
        id: 'technicalTerrain',
        icon: 'technical',
        css: 'technical',
        label: 'Technical',
        icons: trailLegendSummer.find((f) => f.id === 'technicalTerrain')?.icons || [],
      },
      {
        id: 'freerideTerrain',
        icon: 'freeride',
        css: 'freeride',
        label: 'Freeride',
        icons: trailLegendSummer.find((f) => f.id === 'freerideTerrain')?.icons || [],
      },
      {
        id: 'jumpTrail',
        icon: 'difficulty-level-green',
        css: 'cross-country',
        label: 'Cross Country',
        icons: trailLegendSummer.find((f) => f.id === 'jumpTrail')?.icons || [],
      },
      {
        id: 'skillStartPark',
        icon: 'difficulty-level-proline',
        css: 'proline',
        label: 'Proline',
        icons: trailLegendSummer.find((f) => f.id === 'skillStartPark')?.icons || [],
      },
      {
        id: 'uphillRoute',
        icon: 'difficulty-level-uphill',
        css: 'uphill',
        label: 'Uphill',
        icons: trailLegendSummer.find((f) => f.id === 'uphillRoute')?.icons || [],
      },
      {
        id: 'hiking',
        icon: 'difficulty-level-hiking',
        css: 'hiking',
        label: 'Hiking',
        icons: trailLegendSummer.find((f) => f.id === 'hiking')?.icons || [],
      },
    ],
  },
  {
    id: 'features',
    label: 'feature',
    order: 4,
    layout: 'drawr',
    season: 'winter',
    modal: {
      url: '',
    },
    fields: [
      {
        id: 'with_snowmaking',
        icon: 'feature-snowmaking-capable',
        css: 'snowmaking-capable',
        label: 'Current Snowmaking',
      },
      {
        id: 'last_24h',
        icon: 'feature-snowmaking-24-hours',
        css: 'snowmaking-24-hours',
        label: 'Snowmaking in Past 24 Hours',
      },
      {
        id: 'gladed_trail',
        icon: 'feature-gladed',
        css: 'gladed',
        label: 'Gladed',
      },
      {
        id: 'race',
        icon: 'feature-race',
        css: 'race',
        label: 'Race',
      },
      {
        id: 'terrainParks',
        icon: 'feature-terrain-park',
        css: 'race',
        label: 'Terrain Park',
      },
    ],
  },
  {
    id: 'groomed',
    label: 'groomed',
    order: 5,
    layout: 'drawr',
    season: 'winter',
    modal: {
      url: '',
    },
    fields: [
      {
        id: 'groomedRecent',
        icon: 'feature-groomed-recent',
        css: 'groomed-recent',
        label: 'Groomed',
      },
      {
        id: 'groomedPm',
        icon: 'feature-groomed-pm',
        css: 'groomed-pm',
        label: 'Groomed PM',
      },
      {
        id: 'groomedAm',
        icon: 'feature-groomed-am',
        css: 'groomed-am',
        label: 'Groomed AM',
      },
    ],
  },

];

export const liftFilter = [
  {
    id: 'status',
    label: 'status',
    fields: [
      {
        id: 'opening',
        icon: 'status-opening',
        css: 'opening',
        label: 'Open',

      },
      {
        id: 'closed',
        icon: 'status-closed',
        css: 'closed',
        label: 'Closed',

      },
      {
        id: 'onhold',
        icon: 'status-on-hold',
        css: 'on-hold',
        label: 'On Hold',

      },
      {
        id: 'expected',
        icon: 'status-expected',
        css: 'expected',
        label: 'Expected',

      },
    ],
  },
];

export const cmsSettings = {
  field_hide_filter_content: [
    {
      value: 'difficulty',
    },
    {
      value: 'groomed',
    },
    {
      value: 'features',
    },
    {
      value: 'featureSize',
    },
    {
      value: 'featureDifficulty',
    },
    {
      value: 'featureDifficultyLegend',
    },
    {
      value: 'tags',
    },
  ],
  field_hide_filter_drawr: [
    {
      value: 'featureDifficulty',
    },
    {
      value: 'groomed',
    },
  ],
  field_hide_lift_col_mobile: [
    {
      value: 'hours',
    },
    {
      value: 'vertical',
    },
  ],
  field_hide_lifts_trails: [
    {
      value: 'lift',
    },
    {
      value: 'crosscountry',
    },
    {
      value: 'hikingtrail',
    },
    {
      value: 'mountainbike',
    },
    {
      value: 'alpinetrail',
    },
  ],
  field_hide_trail_col_desktop: [
    {
      value: 'featureDifficulty',
    },
    {
      value: 'features',
    },
    {
      value: 'groomed',
    },
    {
      value: 'details',
    },
  ],
  field_hide_trail_col_mobile: [
    {
      value: 'featureDifficulty',
    },
    {
      value: 'features',
    },
    {
      value: 'featureSize',
    },
    {
      value: 'groomed',
    },
    {
      value: 'details',
    },
  ],
};

export const MtBachelorMntLoc = {
  SNOWPLOT: 'snowplot',
  SUNRISE: 'sunrise',
  PINE: 'pine',
  CLOUD_CHASER: 'cloudChaser',
  NORTHWEST: 'northwest',
  SUMMIT: ['summit', 'blackops'],
  CURRENT: 'current',
};

export const MtBachelorMntProps = {
  ID: 'id',
  TIMESTAMP: 'timestamp',
  TEMPERATURE: 'temperature',
  WIND_SPEED_AVG: 'windSpeedAvg',
  WIND_SPEED_MAX: 'windSpeedMax',
  WIND_DIR_AVG: 'windDirAvg',
  RH: 'rh',
  DEPTH: 'depth',
};

export const MtnAliases = {
  [MtBachelorMntLoc.SNOWPLOT]: {
    location: 'West Village',
    elevation: '6300\'',
  },
  [MtBachelorMntLoc.SUNRISE]: {
    location: 'Sunrise',
    elevation: '7300\'',
  },
  [MtBachelorMntLoc.PINE]: {
    location: 'Pine Marten',
    elevation: '7800\'',
  },
  [MtBachelorMntLoc.CLOUD_CHASER]: {
    location: 'Cloud Chaser',
    elevation: '7700\'',
  },
  [MtBachelorMntLoc.NORTHWEST]: {
    location: 'Northwest',
    elevation: '8000\'',
  },
  [MtBachelorMntLoc.SUMMIT[0]]: {
    location: 'Summit',
    elevation: '9065\'',
  },
  [MtBachelorMntLoc.SUMMIT[1]]: {
    location: 'Summit',
    elevation: '9065\'',
  },
  [MtBachelorMntLoc.CURRENT]: {
    elevation: '0\'',
  },
};

export const SnowfallUnits = {
  NONE: '',
  INCHES: '"',
};

export const DorApis = {
  SENSORS: 'sensors',
  WEATHER: 'weather',
  CONDITIONS: 'conditions',
  STATUS: 'status',
};

export const Symbols = {
  DASH: '-',
  DOUBLE_DASH: '--',
  DEGREE: '°',
  NA: 'N/A',
};

export const ForecastLengths = {
  SHORT: 'short',
  LONG: 'long',
};

// Status Types used only for DOR Display Boards
export const DisplayBoardStatusTypes = {
  CLOSED: 'closed',
  OPEN: 'open',
  GROOMED: 'groomed',
  RACE: 'racing',
};

export const DisplayBoardStatusMap = {
  [DisplayBoardStatusTypes.CLOSED]: StatusStates.CLOSED,
  [DisplayBoardStatusTypes.OPEN]: StatusStates.OPENING,
  [DisplayBoardStatusTypes.GROOMED]: StatusStates.GROOMED,
  [DisplayBoardStatusTypes.RACE]: StatusStates.RACE,
};

export const AlertType = {
  STANDARD: 'standard',
  WARNING: 'warning',
  CRITICAL: 'critical',
};

export const SnowReportFieldMachineNames = {
  AMOUNT: 'amount',
  OVERNIGHT_AMOUNT: 'overnight_amount',
  BASE_DEPTH: 'base_depth',
  TWENTYFOUR_HOUR: '24_hour',
  FORTYEIGHT_HOUR: '48_hour',
  SEVENTYTWO_HOUR: '72_hour',
  SEVEN_DAY: '7_day',
  THIRTY_DAY: '30_day',
  MONTH: 'month',
  TOTAL: 'total',
};

export const SnowReportMeasurementDisplayNames = {
  [SnowReportFieldMachineNames.AMOUNT]: 'Amount',
  [SnowReportFieldMachineNames.OVERNIGHT_AMOUNT]: 'Overnight',
  [SnowReportFieldMachineNames.BASE_DEPTH]: 'Base-Depth',
  [SnowReportFieldMachineNames.TWENTYFOUR_HOUR]: '24 Hour',
  [SnowReportFieldMachineNames.FORTYEIGHT_HOUR]: '48 Hour',
  [SnowReportFieldMachineNames.SEVENTYTWO_HOUR]: '72 Hour',
  [SnowReportFieldMachineNames.SEVEN_DAY]: '7 Day',
  [SnowReportFieldMachineNames.THIRTY_DAY]: '30 Day',
  [SnowReportFieldMachineNames.MONTH]: 'Month',
  [SnowReportFieldMachineNames.TOTAL]: 'Total',
};
