import { FetchStatus } from '@powdr/constants';

import { SNOW_REPORT_PENDING, SNOW_REPORT_SUCCESS, SNOW_REPORT_ERROR } from '../types';

const initialState = {
  snowReport: null,
  status: FetchStatus.IDLE,
  error: null,
};

const reducer = (data) => {
  const {
    name,
    report,
    date,
    base_depth: baseDepth,
    overnight_amount: overnightAmount,
    computed,
  } = data[data.length - 1];

  return {
    name,
    report,
    date,
    measurements: {
      base_depth: baseDepth,
      overnight_amount: overnightAmount,
      ...computed,
    },

  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SNOW_REPORT_PENDING:
      return {
        ...state,
        status: FetchStatus.LOADING,
      };
    case SNOW_REPORT_SUCCESS:
      return {
        ...state,
        snowReport: reducer(action.payload),
        status: FetchStatus.SUCCESS,
      };
    case SNOW_REPORT_ERROR:
      return {
        status: FetchStatus.ERROR,
        error: action.payload,
      };
    default: return state;
  }
};
