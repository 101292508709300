// eslint-disable-next-line import/no-unresolved
import PropTypes from 'prop-types';
import React, { useRef, useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';

import { StyledFormSilverpop } from './styles';

export const FormSilverpop = ({
  formCode,
  parentColorProfile,
}) => {
  const formRef = useRef(null);

  const formatForm = () => {
    const form = formRef.current;
    const email = localStorage.getItem('email') || '';
    form.querySelectorAll('input,div,td,select').forEach((i) => { i.removeAttribute('style'); });
    form.querySelectorAll('select,.textInput,input[type="text"]').forEach((i) => { i.classList.add('form-control'); });
    form.querySelectorAll('input[type="submit"]').forEach((i) => { i.classList.add('form-button'); });
    form.querySelectorAll('.checkbox').forEach((i) => { i.classList.remove('checkbox'); i.classList.add('checkbox-alt'); });
    form.querySelectorAll('.required').forEach((i) => {
      i.parentElement.parentElement.parentElement.querySelectorAll('input').forEach((j) => { j.setAttribute('required', ''); });
    });
    Array.from(form.querySelectorAll('input')).filter((input) => ['email', 'Email', 'EMAIL'].some((i) => input.outerHTML.includes(i)))[0].value = email;
  };

  useEffect(() => {
    formatForm();
  }, [formRef]);

  return (
    <StyledFormSilverpop colorProfile={parentColorProfile}>
      {React.createElement('div', { ref: formRef }, ReactHtmlParser(formCode.value))}
    </StyledFormSilverpop>
  );
};

FormSilverpop.propTypes = {
  formCode: PropTypes.shape({
    value: PropTypes.string,
  }).isRequired,
  parentColorProfile: PropTypes.number,
};

FormSilverpop.defaultProps = {
  parentColorProfile: null,
};
