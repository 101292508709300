import React from 'react';

import { CondSectorLengthLabels } from '@powdr/constants';
import { roundIt, setTrailCondBySecLabel } from '@powdr/utils';

import {
  DataShape,
  SettingsShape,
  ThemeShape,
} from './dor-prop-types';
import { StyledLiftsDetails } from './styles';

export const DorLiftsDetailsRender = ({
  data,
  settings,
  theme,
}) => {
  const liftStatus = data?.lifts
    ?.reduce(
      (acc, item) => {
        if (item.status.id === 'opening') {
          acc.open += 1;
        }
        acc.total += 1;
        return acc;
      },
      { open: 0, total: 0 },
    );

  const trailReport = data?.trails?.reduce((acc, trail) => {
    const isOpen = trail.status.id === 'opening';
    const area = trail?.properties?.area || 0;
    const length = trail?.properties?.length || 0;

    return {
      open: acc.open + (isOpen ? 1 : 0),
      total: data.trails.length,
      area: {
        label: setTrailCondBySecLabel(
          CondSectorLengthLabels.SKIABLE_ACRES,
          CondSectorLengthLabels.RIDABLE_ACRES,
          CondSectorLengthLabels.ACRES,
        ),
        open: acc.area.open + (isOpen ? area : 0),
        total: acc.area.total + area,
      },
      length: {
        label: setTrailCondBySecLabel(
          CondSectorLengthLabels.SKIABLE_MILES,
          CondSectorLengthLabels.RIDABLE_MILES,
          CondSectorLengthLabels.MILES,
        ),
        open: acc.length.open + (isOpen ? length : 0),
        total: acc.length.total + length,
      },
    };
  }, {
    open: 0,
    total: data.trails.length,
    area: { open: 0, total: 0 },
    length: { open: 0, total: 0 },
  });

  return (

    <StyledLiftsDetails colorProfile={theme.parentColorProfile}>
      {settings.liftsConditions.LIFTS
        && (
          <li className="item">
            <strong>Lifts Open</strong>
            {liftStatus?.open}
            {' of '}
            {liftStatus?.total}
          </li>
        )}

      {settings.liftsConditions.ACRES
        && (
          <li className="item">
            <strong>{trailReport?.area?.label}</strong>
            {' '}
            {roundIt(trailReport?.area?.open, 2)}
            {' of '}
            {roundIt(trailReport?.area?.total, 2)}
          </li>
        )}

      {settings.liftsConditions.MILES
        && (
          <li className="item">
            <strong>{trailReport?.length?.label}</strong>
            {' '}
            {roundIt(trailReport?.length?.open, 2)}
            {' of '}
            {roundIt(trailReport?.length?.total, 2)}
          </li>
        )}
    </StyledLiftsDetails>
  );
};

DorLiftsDetailsRender.propTypes = {
  data: DataShape.isRequired,
  settings: SettingsShape.isRequired,
  theme: ThemeShape.isRequired,
};
