import styled, { css } from 'styled-components';

import { Icon } from '@powdr/components';
import {
  ThemeProps as Prop, Components, Breakpoints,
  FontSize,
  FontFamily,
} from '@powdr/constants';
import { componentColor, getFontStyles } from '@powdr/utils';

const CPNT = Components.DOR_TOGGLE;

export const StyledDorToggle = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: all;
  height: 100%;
  ${() => getFontStyles(FontFamily.SECONDARY_FONT, FontSize.MED50, undefined, undefined)};
  gap: 10px;
  padding: 10px;
  background-color: ${() => componentColor(Prop.BACKGROUND, CPNT, Prop.BTN_BG)};
  color: ${() => componentColor(Prop.CONTENT, CPNT, Prop.BTN_TXT)};

  ${Breakpoints.mobile(css`
    display: grid;
    ${({ $isInfoHidden }) => (($isInfoHidden)
    ? 'grid-template-columns: repeat(1, 1fr);'
    : 'grid-template-columns: repeat(2, 1fr); column-gap: 5px;')};
    ${() => getFontStyles(FontFamily.SECONDARY_FONT, FontSize.MED10, undefined, undefined)};
    gap: 0;
    width: 105px;
    padding: 5px;
  `)}


  ${Breakpoints.portraitSmall(css`
    width: 92px;
  `)}
`;

const sharedDataBlockStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  height: 100%;

  svg {
    fill: ${() => componentColor(Prop.ICON, CPNT, Prop.BTN_TXT)};
    color: ${() => componentColor(Prop.ICON, CPNT, Prop.BTN_TXT)};
  }

  ${Breakpoints.mobile(css`
    gap: 1px;
    width: 100%;
    justify-content: space-around;
  `)}
`;

const sharedIconStyles = css`
  width: 25px;
  height: 25px;

  ${Breakpoints.mobile(css`
    width: 18px;
    height: 18px;
  `)}
`;

export const TemperatureInfo = styled.div`
  ${sharedDataBlockStyles}
`;

export const TempIcon = styled(Icon)`
  ${sharedIconStyles}
`;

export const SnowfallInfo = styled.div`
  ${sharedDataBlockStyles}
`;

export const SnowfallIcon = styled(Icon)`
  ${sharedIconStyles}
`;

export const InfoWrapper = styled.div`
  display: flex;
`;

export const IndicatorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  ${Breakpoints.mobile(css`
    grid-column: span 2;
  `)}

`;

export const IndicatorIcon = styled(Icon)`
  width: 25px;

  svg {
    fill: ${() => componentColor(Prop.ICON, CPNT, Prop.BTN_TXT)};
    color: ${() => componentColor(Prop.ICON, CPNT, Prop.BTN_TXT)};
  }

  ${Breakpoints.mobile(css`
    height: 10px;
    width: auto;
  `)}
`;
