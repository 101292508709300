import styled, { css } from 'styled-components';

import {
  ThemeProps as Prop,
  Breakpoints,
  Components,
  AlertType,
  StaticProfiles,
  GlobalLayoutValues,
} from '@powdr/constants';
import { componentColor } from '@powdr/utils';

const extraTrayHeight = 50; // px

const CPNT = Components.SIDEBAR_TRAY;

const getColor = (component, prop) => {
  const AlertColorMap = {
    [AlertType.STANDARD]: {
      [Prop.BACKGROUND]: Prop.BACKGROUND,
      [Prop.CONTENT]: Prop.CONTENT,
    },
    [AlertType.WARNING]: {
      [Prop.BACKGROUND]: Prop.ALERT_WARNING_BG,
      [Prop.CONTENT]: Prop.ALERT_WARNING_CONTENT,
    },
    [AlertType.CRITICAL]: {
      [Prop.BACKGROUND]: Prop.ALERT_CRITICAL_BG,
      [Prop.CONTENT]: Prop.ALERT_CRITICAL_CONTENT,
    },
  };

  if (component?.typeName === Components.DOR_MINI_ALERT) {
    return componentColor(
      (component?.type)
        ? AlertColorMap[component?.type][prop]
        : AlertColorMap[AlertType.STANDARD][prop],
      CPNT,
      (component?.type !== AlertType.STANDARD && component?.type) && StaticProfiles.ALERTS,
    );
  }

  return componentColor(prop, CPNT);
};

export const StyledSidebarTray = styled.div``;

export const SidebarTrayAnimationWrapper = styled.div`
  overflow: hidden;

  ${Breakpoints.desktopOnly(css`
    position: absolute;
    height: auto;
    width: 35vw;
    max-width: 675px;
    top: -${extraTrayHeight / 2}px;
    right: ${({ $offsetWidth }) => $offsetWidth}px;
    box-shadow: ${({ $isOpen }) => (($isOpen) ? '-3px -4px 40px 0px darkgrey' : 'none')};
    transform: ${({ $isOpen }) => (($isOpen) ? 'scaleX(1)' : 'scaleX(0)')};
    transform-origin: right;
    transition:
      ${({ $isOpen }) => ($isOpen) && `box-shadow ${GlobalLayoutValues.ALL_PLATFORM.WRAPPER.ANIMATION_DURATION}ms 100ms ease-in-out,`}
      width ${GlobalLayoutValues.ALL_PLATFORM.WRAPPER.ANIMATION_DURATION}ms ease-in-out,
      transform ${GlobalLayoutValues.ALL_PLATFORM.WRAPPER.ANIMATION_DURATION}ms ease-in-out;
  `)}

  ${Breakpoints.mobile(css`
    width: 100%;
    height: auto;
    bottom: ${({ $sidebarHeight }) => $sidebarHeight}px;
    position: absolute;
    transform: ${({ $isOpen }) => (($isOpen) ? 'scaleY(1)' : 'scaleY(0)')};
    transform-origin: bottom;
    transition: transform ${GlobalLayoutValues.ALL_PLATFORM.WRAPPER.ANIMATION_DURATION}ms ease-in-out;
  `)}
`;

export const SidebarTrayInner = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  color: ${({ $component }) => getColor($component, Prop.CONTENT)};
  background-color: ${({ $component }) => getColor($component, Prop.BACKGROUND)};

  ${Breakpoints.mobile(css`
    flex-direction: column;
  `)}
`;

const sharedCloseButtonStyles = css`
  height: 100%;
  background-color: ${({ $component }) => getColor($component, Prop.BACKGROUND)};

  svg {
    fill: ${({ $component }) => getColor($component, Prop.CONTENT)};
    color: ${({ $component }) => getColor($component, Prop.CONTENT)};
  }
`;

export const CloseButtonDesktop = styled.button`
  ${sharedCloseButtonStyles}
  padding: 0 15px;

  ${Breakpoints.mobile(css`
    display: none;
  `)}
`;

export const CloseButtonMobile = styled.button`
  ${sharedCloseButtonStyles}
  padding: 8px;

  ${Breakpoints.desktopOnly(css`
    display: none;
  `)}
`;

export const ComponentWrapper = styled.div`
  width: 100%;
  height: 100%;

  ${Breakpoints.mobile(css`
    padding: 0 15px;
  `)}
`;

const trayDecorationSize = 55; // px

export const TrayDecoration = styled.div`
  flex: 0 0 auto;
  position: relative;
  height: 100%;
  width: ${trayDecorationSize}px;

  &:after {
    content: " ";
    position: absolute;
    top: calc(50% - ${trayDecorationSize}px);
    right: 0;
    border: solid ${({ $component }) => getColor($component, Prop.CONTENT)};
    border-width: ${trayDecorationSize}px;
    border-top: ${trayDecorationSize}px solid transparent;
    border-bottom: ${trayDecorationSize}px solid transparent;
    border-left: none;
    width: 0;
    height: 0;
    z-index: 2;
  }

  ${Breakpoints.mobile(css`
    display: none;
  `)}
`;
