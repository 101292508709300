import styled, { css } from 'styled-components';

import {
  Components, FontFamily, FontSize, ThemeProps as Prop, StaticProfiles,
} from '@powdr/constants';
import { themeUtil, getDorComponentColor, getFontStyles } from '@powdr/utils';

const CPNT = Components.DOR;

export const StyledDorPercentWheel = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`;

export const ProgressCircleContainer = styled.div`
  transform: rotate(-90deg);
`;

export const ProgressTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  text-align: center;
  gap: 5px;
`;

const SharedTextStyles = css`
  line-height: 1;
  color:${({ isParentNavFeed, colorProfile }) => getDorComponentColor(isParentNavFeed, colorProfile, Prop.CONTENT, CPNT)};
`;

export const TextPrimary = styled.div`
  ${SharedTextStyles}
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.LRG12, undefined, undefined)};
`;

export const TextSecondary = styled.div`
  ${SharedTextStyles}
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.REG40, undefined, undefined)};
`;

export const ProgressSVG = styled.svg`
  .meter,
  .value {
    fill: none;
    transform: rotate(-90deg);
    transform-origin: 50%;
  }

  .meter {
    stroke: ${() => themeUtil(Prop.LIGHTER, CPNT, StaticProfiles.SHADES)};
  }

  .value {
    stroke: ${({ isParentNavFeed, colorProfile }) => getDorComponentColor(isParentNavFeed, colorProfile, Prop.ICON, CPNT)};
    stroke-linecap: butt;
  }

  text {
    fill: ${({ isParentNavFeed, colorProfile }) => getDorComponentColor(isParentNavFeed, colorProfile, Prop.CONTENT, CPNT)};
    ${({ theme }) => theme.fonts.PRI_BDY_MED};
    white-space: nowrap;
  }
`;
