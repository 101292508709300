/* eslint-disable no-nested-ternary */
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { Season } from '@powdr/constants';
import { AppContext } from '@powdr/context';
import { useStaticData } from '@powdr/web/src/hooks';

import { StyledHeaderLogo } from './styles';

export const HeaderLogo = ({
  className,
  isFromDrawer,
  isHideNav,
  isFromDemo,
  isLandingPage,
}) => {
  const { defaultSeason, headerConfig, siteName } = useStaticData();
  const {
    isMobile,
    themeConfig,
    svgs,
  } = useContext(AppContext);

  return (
    <StyledHeaderLogo
      className={`header-logo ${className}`}
      $isFromDrawer={isFromDrawer}
      isHideNav={isHideNav}
      containerWidth={`${themeConfig.layout.headerLogoContainerWidth}px`}
      isDorFeedEnabled={(headerConfig.isDorFeedEnabled && !isLandingPage)}
      headerLogoHeight={(themeConfig.layout?.headerLogoHeight && !isMobile) ? `${themeConfig.layout.headerLogoHeight}px` : '100%'}
      isFromDemo={isFromDemo}
      isMobile={isMobile}
    >
      <Link
        to={defaultSeason === Season.SUMMER ? `/?season=${Season.SUMMER}` : '/'}
        aria-label="Return to Homepage"
        title={siteName}
      >
        {(isFromDrawer) ? <svgs.SiteLogoDrawer /> : <svgs.SiteLogoWrapper />}
      </Link>
    </StyledHeaderLogo>
  );
};

HeaderLogo.propTypes = {
  className: PropTypes.string,
  isFromDrawer: PropTypes.bool,
  isHideNav: PropTypes.bool,
  isFromDemo: PropTypes.bool,
  isLandingPage: PropTypes.bool,
};

HeaderLogo.defaultProps = {
  className: '',
  isFromDrawer: false,
  isHideNav: false,
  isFromDemo: false,
  isLandingPage: false,
};
