/* eslint-disable object-curly-newline */
/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';

import {
  ThemeProps as Prop, Components, Breakpoints,
} from '@powdr/constants';
import { componentColor } from '@powdr/utils';

const CPNT = Components.HEADER;

export const StyledHeaderLogo = styled.div`
  display: block;
  margin: 0;
  padding: 0;
  height: 100%;
  pointer-events: all;

  a {
    display: block;
    height: 100%;
    width: 100%;

    ${Breakpoints.mobile(css`
      svg {
        max-width: 155px;
      }
    `)}

    svg {
      height: 100%;
    }
  }

  &:focus-within {
    outline: 2px solid ${() => componentColor(Prop.LOGO_FOCUS_COLOR, CPNT, Prop.LINK_TXT_HOVER)};
    outline-offset: 2px;
  }
`;
