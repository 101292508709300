import PropTypes from 'prop-types';
import React from 'react';

import { HtmlContentLoader, WysiwygStyleWrapper } from '@powdr/components';
import { Alignment } from '@powdr/constants';
import { colorProfileByIndex } from '@powdr/utils';

import { StyledUiFooter } from './styles';

export const UiFooter = ({
  footerAlignment,
  html,
  paddingTop,
  paddingBottom,
  colorProfile,
  parentColorProfile,
}) => {
  const trueColorProfile = (colorProfile) ? colorProfileByIndex(colorProfile) : parentColorProfile;

  return (
    <StyledUiFooter
      alignment={footerAlignment}
      colorProfile={trueColorProfile}
      $paddingTop={paddingTop}
      $paddingBottom={paddingBottom}
    >
      <WysiwygStyleWrapper>
        <HtmlContentLoader html={html?.value} allowRerender />
      </WysiwygStyleWrapper>
    </StyledUiFooter>
  );
};

UiFooter.propTypes = {
  html: PropTypes.shape({
    value: PropTypes.string,
  }),
  footerAlignment: PropTypes.string,
  paddingBottom: PropTypes.number,
  paddingTop: PropTypes.number,
  colorProfile: PropTypes.string,
  parentColorProfile: PropTypes.string,
};

UiFooter.defaultProps = {
  html: { value: '' },
  footerAlignment: Alignment.CENTER,
  paddingBottom: 50,
  paddingTop: 50,
  colorProfile: null,
  parentColorProfile: null,
};
