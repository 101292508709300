import { format, parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import React, {
  useEffect, useState,
} from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useDispatch, useSelector } from 'react-redux';

import { Icon, Link } from '@powdr/components';
import {
  HideOptions, DateFormats, Layouts, Components,
} from '@powdr/constants';
import { SnowReportMeasurementDisplayNames } from '@powdr/constants/dor';
import { getSnowReport } from '@powdr/stores/actions/snow-report-action';
import {
  slugify, getSnowfallMeasurementUnit, capitalizeAllWords, camalizeToTitle,
  camalize,
  deslugify,
} from '@powdr/utils';

import {
  StyledDorSnowReport,
  StaticContent,
  ReportDataItems,
  ReportDataItem,
  Timestamp,
  DataSection,
  ReportSection,
  ReportContentWrapper,
  ItemAlignmentWrapper,
  ItemIcon,
} from './styles';

export const DorSnowReport = ({
  hide,
  layout,
  title,
  html,
  isParentNavFeed,
  navFeedLink,
  showIcons,
  measurementsToShow,
  parentColorProfile,
}) => {
  const dispatch = useDispatch();
  const useSnowReportData = useSelector((state) => state.snowReport);
  const { snowReport } = useSnowReportData;
  const measurementUnit = getSnowfallMeasurementUnit();

  useEffect(() => {
    dispatch(getSnowReport());
  }, [dispatch]);

  return (
    <StyledDorSnowReport
      colorProfile={parentColorProfile}
      className="dor-snow-report"
      $layout={layout || Layouts.STACKED}
      $isParentNavFeed={isParentNavFeed}
    >
      <DataSection>
        {((title || html)) && (
          <StaticContent colorProfile={parentColorProfile} component={Components.DOR_SNOW_REPORT}>
            {title && (<h3>{title}</h3>)}
            {html && ReactHtmlParser(html)}
          </StaticContent>
        )}
        {snowReport && (
          <ReportDataItems
            $layout={layout || Layouts.STACKED}
            $numItems={Object.entries(snowReport.measurements)
              .filter(([k]) => measurementsToShow
                .map((filterItem) => filterItem)
                .includes(k)).length}
          >
            {Object.entries(snowReport.measurements)
              .filter(([k]) => measurementsToShow
                .map((filterItem) => filterItem)
                .includes(k))
              .map(([k, v], idx) => (
                <ReportDataItem
                  key={slugify(k)}
                  $layout={layout || Layouts.STACKED}
                  $colorProfile={parentColorProfile}
                  $index={idx + 1}
                  $numItems={Object.entries(snowReport.measurements).length}
                >
                  <ItemAlignmentWrapper>
                    <h3 className="duration">
                      {SnowReportMeasurementDisplayNames[k]}
                    </h3>
                    {(showIcons) && <ItemIcon name="dor-snow" />}
                    <p className="amount">
                      {v}
                      {measurementUnit}
                    </p>
                  </ItemAlignmentWrapper>
                </ReportDataItem>
              ))}
          </ReportDataItems>
        )}
      </DataSection>
      {(snowReport?.date || snowReport?.report) && (
        <ReportSection
          colorProfile={parentColorProfile}
          $isParentNavFeed={isParentNavFeed}
        >
          {(snowReport?.date && !hide.includes(HideOptions.DATE)) && (
            <Timestamp colorProfile={parentColorProfile}>
              {format(parseISO(snowReport.date), DateFormats.PRETTY_TIMESTAMP)}
            </Timestamp>
          )}
          {(snowReport?.report && !hide.includes(HideOptions.HTML)) && (
            <ReportContentWrapper $isParentNavFeed={isParentNavFeed}>
              {ReactHtmlParser(snowReport.report)}
            </ReportContentWrapper>
          )}
          {(navFeedLink?.href) && <Link href={navFeedLink.href}>{navFeedLink.text}</Link>}
        </ReportSection>
      )}
    </StyledDorSnowReport>
  );
};

DorSnowReport.propTypes = {
  layout: PropTypes.string,
  title: PropTypes.string,
  html: PropTypes.string,
  isParentNavFeed: PropTypes.bool,
  hide: PropTypes.arrayOf(PropTypes.string),
  showIcons: PropTypes.bool,
  measurementsToShow: PropTypes.arrayOf(PropTypes.shape({})),
  navFeedLink: PropTypes.shape({
    href: PropTypes.string,
    text: PropTypes.string,
  }),
  parentColorProfile: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

DorSnowReport.defaultProps = {
  layout: Layouts.STACKED,
  title: null,
  html: null,
  showIcons: false,
  isParentNavFeed: false,
  measurementsToShow: ['overnight', '7 days'],
  hide: [],
  parentColorProfile: null,
  navFeedLink: null,
};
