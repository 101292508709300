import PropTypes from 'prop-types';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';

import { Icon } from '@powdr/components';
import { liftTableColumns } from '@powdr/constants';

import {
  SettingsShape,
  ThemeShape,
  LiftShape,
} from './dor-prop-types';
import { StyledDorTable } from './styles';

// Define the component
export const DorLiftTableRender = ({
  rows,
  theme,
  settings,
}) => (
  <StyledDorTable
    top={`${settings?.tableThStickyOffset}`}
    colorProfile={theme?.parentColorProfile}
    className={`${theme} table table-strip animated fadeInUp u-an-de-0`}
  >

    <thead>

      <tr>

        {liftTableColumns?.map((th) => !settings?.hideLiftCol.includes(th.id) && (

          <th key={th.id} className={`${th.className} ${settings.isDesktop && 'sticky'}`}>

            {th.label}

          </th>

        ))}

      </tr>

    </thead>

    <tbody>

      {rows?.map((td) => (

        <tr key={td.id}>

          {settings?.liftColumn.NAME && (

            <td className="name">

              {td.name}

            </td>

          )}

          {settings?.liftColumn.STATUS && (

            <td className="status">

              {td.status?.icon && (

                <Icon
                  className="icon"
                  name={`status-${td.status.icon}`}
                  width="23"
                  height="23"
                />

              )}

            </td>

          )}

          {settings?.liftColumn.TYPE && (

            <td className="type">

              {td.type || ''}

            </td>

          )}

          {settings?.liftColumn.HOURS && (

            <td className="hours">

              {td.hours || 0}

            </td>

          )}

          {settings?.liftColumn.VERTICAL && (

            <td className="vertical">

              {td.vertical || 0}

            </td>

          )}

          {settings?.liftColumn.WAIT_TIME && (

            <td className="wait-time">

              {td.waitTime || ''}

            </td>

          )}

          {settings?.liftColumn.NOTES && (

            <td className="notes">

              {ReactHtmlParser(td.notes || '')}

            </td>

          )}

        </tr>
      )) || []}

    </tbody>

  </StyledDorTable>
);

DorLiftTableRender.propTypes = {
  rows: PropTypes.arrayOf(LiftShape).isRequired,
  settings: SettingsShape.isRequired,
  theme: ThemeShape.isRequired,
};
