import styled, { css } from 'styled-components';

import { Breakpoints } from '@powdr/constants';

export const StyledMainNav = styled.nav`

`;

export const MainNavigationList = styled.ul`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 30px;
  row-gap: 150px;
  max-width: 1440px;
  width: 100%;
  margin: 0 100px;

  ${Breakpoints.mobile(css`
    grid-template-columns: repeat(1, 1fr);
    margin: 0;
    column-gap: 0px;
    row-gap: 15px;
    max-width: unset;
    padding: 0 30px;
  `)}
`;
