import React from 'react';
import styled from 'styled-components';

import { DemoHeaderBanner } from '@powdr/components/demos';
import {
  Components, ThemeProps as Prop,
  FontFamily,
  FontSize,
} from '@powdr/constants';
import { themeUtil, componentColor, getFontStyles } from '@powdr/utils';

const CPNT = Components.HEADER;

const JumpButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  ${() => getFontStyles(FontFamily.SECONDARY_FONT, FontSize.REG30, undefined, undefined)};
  line-height: ${FontSize.REG30};
  color: ${() => componentColor(Prop.NAV_ITEM_TXT_SOLID, CPNT, Prop.CONTENT)};
  background-color: ${() => componentColor(Prop.NAV_BG, CPNT, Prop.BACKGROUND)};
  transition: all 0.25s ease-in-out;
  border-bottom: 1px solid ${() => themeUtil(Prop.BORDER, CPNT)};

  &:hover {
    color: ${() => componentColor(Prop.NAV_ITEM_TXT_ACTIVE, CPNT, Prop.CONTENT)};
    background-color: ${() => componentColor(Prop.NAV_ITEM_BG_ACTIVE, CPNT, Prop.BACKGROUND)};
  }
`;

export const DemoPageJump = () => (
  <DemoHeaderBanner>
    <JumpButton href="#">Jump to Top of Page</JumpButton>
  </DemoHeaderBanner>

);

DemoPageJump.propTypes = {};

DemoPageJump.defaultProps = {};
