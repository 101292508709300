// eslint-disable-next-line import/no-unresolved
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { Icon } from '@powdr/components';
import { AbsolutePositions, AlignmentShorthand, Components } from '@powdr/constants';
import { AppContext } from '@powdr/context';

import { DrawerCloseButton, StyledComponentDrawer } from './styles';

export const ComponentDrawer = ({
  isOpen,
  slideInFrom,
  closeDrawerFunc,
  closeButtonPosition,
  componentTheme,
  className,
  children,
}) => {
  const { isMobile } = useContext(AppContext);

  return (
    <StyledComponentDrawer
      className={className}
      $componentTheme={componentTheme}
      $isOpen={isOpen || false}
      $slideInFrom={slideInFrom || AbsolutePositions.RIGHT}
    >
      {children}

      {/* Close Button */}
      <DrawerCloseButton
        onClick={closeDrawerFunc}
        $position={closeButtonPosition || AlignmentShorthand.TOP_RIGHT}
      >
        <Icon className="drawer-close-button" name="ui-close" width={(isMobile) ? 25 : 40} />
      </DrawerCloseButton>

    </StyledComponentDrawer>
  );
};

ComponentDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  slideInFrom: PropTypes.oneOf(Object.values(AbsolutePositions)).isRequired,
  closeButtonPosition: PropTypes.oneOf(Object.values(AlignmentShorthand)),
  componentTheme: PropTypes.string,
  closeDrawerFunc: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

ComponentDrawer.defaultProps = {
  componentTheme: Components.COMPONENT_DRAWER,
  closeButtonPosition: AlignmentShorthand.TOP_RIGHT,
};
