import PropTypes from 'prop-types';
import React from 'react';

import { Symbols } from '@powdr/constants';
import { useSnowfallMeasurement, useTemperature } from '@powdr/hooks';
import { useStaticData } from '@powdr/web/src/hooks';

import {
  SnowfallIcon,
  SnowfallInfo,
  StyledDorToggle,
  TempIcon,
  TemperatureInfo,
  IndicatorIcon,
  IndicatorWrapper,
} from './styles';

export const DorToggle = ({
  onClick,
}) => {
  const { headerConfig } = useStaticData();
  const temperatureData = useTemperature();
  const snowfallData = useSnowfallMeasurement();

  return (
    <StyledDorToggle
      onClick={onClick}
      $isInfoHidden={!headerConfig?.feedSnowfallHide || !headerConfig?.feedTempHide}
    >
      {(!headerConfig?.feedSnowfallHide) && (
        <SnowfallInfo>
          <SnowfallIcon name="dor-snow" />
          {`${(snowfallData.value === null) ? Symbols.DOUBLE_DASH : snowfallData.value}${snowfallData.unit}`}
        </SnowfallInfo>
      )}
      {(!headerConfig?.feedTempHide) && (
        <TemperatureInfo>
          <TempIcon name={temperatureData?.icon} />
          {`${(temperatureData.value === null) ? Symbols.DOUBLE_DASH : Math.round(temperatureData.value)}${Symbols.DEGREE}`}
        </TemperatureInfo>
      )}
      <IndicatorWrapper>
        <IndicatorIcon name="ui-arrow-down" />
      </IndicatorWrapper>
    </StyledDorToggle>
  );
};

DorToggle.propTypes = {
  onClick: PropTypes.func.isRequired,
};

DorToggle.defaultProps = {};
