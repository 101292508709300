import PropTypes from 'prop-types';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';

import {
  StyledDorMiniAlert, Title, Message,
} from './styles';

export const DorMiniAlert = ({
  className,
  height,
  title,
  content,
}) => (
  <StyledDorMiniAlert
    className={className}
    $height={height}
  >
    {(content) && <Title>{title}</Title>}
    {(content) && <Message>{ReactHtmlParser(content?.value)}</Message>}
  </StyledDorMiniAlert>
);

DorMiniAlert.propTypes = {
  className: PropTypes.string,
  height: PropTypes.number,
  title: PropTypes.string,
  content: PropTypes.shape({
    value: PropTypes.string,
  }),
};

DorMiniAlert.defaultProps = {
  className: '',
  height: null,
  title: null,
  content: {
    value: null,
  },
};
