import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';

import {
  Icon,
  PrimaryCarousel,
} from '@powdr/components';
import {
  CarouselControlPlacement,
  MediaTypes,
  trailTableColumns,
} from '@powdr/constants';

import {
  SettingsShape,
  ThemeShape,
  TrailShape,
  ActiveRowsShape,
  ActiveExpandedRowsShape,
  HandleCheckboxClickShape,
  HandleOnExpandClickCloseShape,
} from './dor-prop-types';
import { StyledDorTable } from './styles';

export const DorTrailTableRender = ({
  rows,
  settings,
  theme,
  activeRows,
  activeExpandedRows,
  handleCheckboxClick,
  handleOnExpandClickClose,
}) => (
  <StyledDorTable
    top={settings?.tableThStickyOffset}
    colorProfile={theme?.parentColorProfile}
    className="table table-strip animated fadeInUp"
  >
    <thead>

      <tr>

        {trailTableColumns?.map((th) => !settings?.hideTrailCol?.includes(th.id) && (

          <th key={th.id} className={`${th.className} ${settings.isDesktop && 'sticky'}`}>

            {th.label}

          </th>

        ))}

      </tr>

    </thead>

    <tbody>

      {rows?.filter((td) => (activeRows.length > 0

        ? activeRows.includes(td.id)
        : true))
        .map((td) => (

          <React.Fragment key={td.id}>

            <tr>

              {settings.trailColumn.NAME && (

                <td className="name">

                  {td.name}

                </td>

              )}

              {settings.trailColumn.STATUS && (

                <td className="status">

                  {td.status?.icon && (

                    <Icon
                      className={`icon ${td.status.icon}`}
                      name={`status-${td.status.icon}`}
                      width="23"
                      height="23"
                    />

                  )}

                </td>

              )}

              {settings.trailColumn.DIFFICULTY && (

                <td className="difficulty">

                  {td.difficulty?.icon && (

                    <Icon
                      className={`icon ${td.difficulty.icon}`}
                      name={td.difficulty?.icon}
                      width="23"
                      height="23"
                    />

                  )}

                </td>

              )}

              {settings.trailColumn.FEATURE_DIFFICULTY && (

                <td className="difficulty">

                  {td.featureDifficulty

                    ?.filter((f) => !settings?.hideFeatureDiff
                      ?.includes(f.icon))

                    .map((f) => f.icon && (

                      <Icon
                        key={f.icon}
                        className={`icon ${f.icon}`}
                        name={f.icon}
                        width="23"
                        height="23"
                      />

                    ))}

                </td>

              )}

              {settings.trailColumn.FEATURE_SIZE && (

                <td className="feature-size">

                  {td.featureSize

                    ?.map((f) => f.icon && (

                      <Icon
                        key={f.icon}
                        className="icon"
                        name={f.icon}
                        width="32"
                        height="23"
                      />

                    ))}

                </td>

              )}

              {settings.trailColumn.GROOMED && (

                <td className="groomed">

                  {td.groomed?.icon && (

                    <Icon
                      className="icon"
                      name={td.groomed.icon}
                      height="23"
                    />

                  )}

                </td>

              )}

              {settings.trailColumn.FEATURES && (

                <td className="icons features">

                  {td.feature?.map((f) => f?.icon && (

                    <Icon
                      key={f.id}
                      className={`icon ${f.id}`}
                      name={f.icon}
                      width="18"
                      height="18"
                    />

                  ))}

                </td>

              )}

              {settings.trailColumn.NOTES && (

                <td className="notes">

                  {ReactHtmlParser(td.notes || '')}

                </td>

              )}

              {settings.trailColumn.DESCRIPTION && (

                <td className="description">

                  {ReactHtmlParser(td.description || '')}

                </td>

              )}

              {settings.trailColumn.DETAILS && (

                <td className="details">

                  {td.expandDetails && (

                    <label
                      aria-label="expand button"
                      htmlFor={`expand-${td.id}`}
                      className="expand-input-checkbox"
                    >

                      <input
                        id={`expand-${td.id}`}
                        name={`expand-${td.id}`}
                        onChange={(e) => handleCheckboxClick(e, activeExpandedRows)}
                        type="checkbox"
                        value={td.id}
                      />

                      <Icon
                        className="icon content-info-open"
                        name={!activeExpandedRows.includes(`${td.id}`)
                          ? 'ui-arrow-down'
                          : 'ui-arrow-up'}
                        width="13"
                        height="13"
                      />

                    </label>

                  )}

                </td>
              )}

            </tr>

            {settings.trailColumn.DETAILS

              && activeExpandedRows?.includes(`${td.id}`)

              && (

                <tr className="expanded-row">

                  <td colSpan={Object.values(settings.trailColumn).filter(Boolean).length}>

                    <div
                      className={classNames(
                        'expanded-td animated fadeInUp u-an-de-0',
                        { 'col-2': td.expandDetails?.slides.length > 0 },
                      )}
                    >
                      {td.expandDetails?.slides.length > 1 ? (
                        <div className="col media">

                          <PrimaryCarousel
                            slidesPerView={1}
                            controlSettings={{
                              iconName: 'ui-arrow-left',
                              size: 15,
                              placement: CarouselControlPlacement.ON_SLIDE,
                            }}
                          >

                            {td.expandDetails.slides.map((slide) => {
                              if (slide.url) {
                                if (MediaTypes.IMAGE?.includes(slide.type)) {
                                  return <img key={slide.url} src={slide.url} alt="slide" />;
                                }

                                if (MediaTypes.VIDEO.includes(slide.type)) {
                                  return (
                                    <video key={slide.url} muted controls>
                                      <source src={slide.url} type="video/mp4" />
                                    </video>
                                  );
                                }
                              }
                              return null;
                            })}
                          </PrimaryCarousel>
                        </div>
                      ) : (
                        td.expandDetails.slides[0]?.url && (
                          <div className="col media">
                            <img src={td.expandDetails.slides[0].url} alt="slide" />
                          </div>
                        )
                      )}

                      {td.expandDetails && (
                        <>
                          <div className="col content">

                            {td.label && <h3>{td.trail.label}</h3>}

                            {td.description && ReactHtmlParser(td.description)}

                            {td.expandDetails.what && (

                              <p>

                                <strong>What: </strong>

                                {td.expandDetails.what}

                              </p>

                            )}

                            {td.expandDetails.who && (

                              <p>

                                <strong>Who: </strong>

                                {td.expandDetails.who}

                              </p>

                            )}

                            {td.expandDetails.where && (

                              <p>

                                <strong>Where: </strong>

                                {td.expandDetails.where}

                              </p>

                            )}

                            {td.expandDetails.when && (

                              <p>

                                <strong>When: </strong>

                                {td.expandDetails.when}

                              </p>

                            )}

                            {td.expandDetails.cta?.url && (

                              <a
                                className="btn"
                                target="_blank"
                                href={td.expandDetails.cta.url}
                                rel="noreferrer"
                              >

                                {td.expandDetails.cta.label}

                              </a>

                            )}

                          </div>

                          <button
                            aria-label="Mute volume"
                            className="expand-close-btn"
                            type="button"
                            onClick={() => handleOnExpandClickClose(`${td.id}`)}
                          >

                            <Icon
                              className="icon content-info-open"
                              name="content-info-close"
                              width="33"
                              height="33"
                            />

                          </button>

                        </>

                      )}

                    </div>

                  </td>

                </tr>
              )}

          </React.Fragment>

        ))}

    </tbody>

  </StyledDorTable>

);

// Define prop types for the component
DorTrailTableRender.propTypes = {
  rows: PropTypes.arrayOf(TrailShape).isRequired,
  settings: SettingsShape.isRequired,
  theme: ThemeShape.isRequired,
  activeRows: ActiveRowsShape.isRequired,
  activeExpandedRows: ActiveExpandedRowsShape.isRequired,
  handleCheckboxClick: HandleCheckboxClickShape.isRequired,
  handleOnExpandClickClose: HandleOnExpandClickCloseShape,
};

DorTrailTableRender.defaultProps = {
  handleOnExpandClickClose: () => {},
};
