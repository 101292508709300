/* eslint-disable import/no-unresolved */
import styled, { css } from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import 'swiper/css/controller';
import 'swiper/css/effect-fade';
import 'swiper/css/effect-cards';
import 'swiper/css/scrollbar';
import 'swiper/css/free-mode';
import 'swiper/css/a11y';
import 'swiper/css/thumbs';
import 'swiper/css/parallax';

import { Breakpoints } from '@powdr/constants';

export const StyledPrimaryCarousel = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

export const CarouselControlWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  gap: 15px;
`;

export const CarouselWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const StyledSwiper = styled(Swiper)`
  width: 100%;
  height: 100%;

  ${({ $isThumbnailCarousel }) => ($isThumbnailCarousel) && 'padding-top: 10px !important;'};
`;

export const StyledSwiperSlide = styled(SwiperSlide)`
  ${({ $fitSlideWidthToItem }) => (($fitSlideWidthToItem) && 'width: auto !important; height: auto;')};

  ${({ $isThumbnail }) => ($isThumbnail) && `
    cursor: pointer;

    :not(.swiper-slide-thumb-active) {
      opacity: 0.6;
    }
  `}
`;
