/* eslint-disable no-lonely-if */
import styled from 'styled-components';

import { Icon } from '@powdr/components/icon';

export const StyledFeatureItem = styled.div``;

export const ItemAlignmentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 12px;
`;

export const ItemIcon = styled(Icon)`
  margin: 2px 15px 0 0;

svg {
  fill: ${({ iconColor }) => iconColor};
  color: ${({ iconColor }) => iconColor};
}
`;

export const ItemText = styled.div`
  line-height: 1.1;
  max-width: 200px;
`;
