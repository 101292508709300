import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

import { Icon } from '@powdr/components/icon';
import { Link } from '@powdr/components/link';
import { BlockTypes, Breakpoints, ThemeProps as Prop } from '@powdr/constants';
import { ContentBlockType } from '@powdr/model';
import { themeUtil, clamp } from '@powdr/utils';

const CPNT = BlockTypes.SOLID;

// styled components
const ContentBlockSolid = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: 330px;
  background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};

  &:focus {
    outline: 2px solid ${({ colorProfile }) => themeUtil(Prop.LINK_TXT, CPNT, colorProfile)};
    outline-offset: 2px;

    .button {
      color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
      background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
      outline: 2px solid ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
      outline-offset: 2px;
    }
  }

  .cb-content-wrapper {
    display: flex;
    align-items: center;
    width: 100%;

    .cb-content {
      display: flex;
      position: relative;
      text-align: left;
      width: 100%;
      padding: 40px;
      background: transparent;
      z-index: 1;

      .cb-icon {
        margin-right: 25px;
      }

      .cb-title {
        ${() => clamp(2)}
        margin-bottom: 10px;
      }

      .cb-copy {
        ${() => clamp(3)}
        display: block;
        margin-bottom: 28px;
      }

      .button {
        display: inline-block;
      }

      ${Breakpoints.mobile(css`
        min-height: 150px;
        padding: 30px 20px;
      `)}
    }
  }
`;

export const Solid = ({
  title, copy, blockType, colorProfile, iconName, ariaLabel,
  buttonEnabled, buttonText, buttonLink, buttonTarget, buttonClick,
}) => (
  <ContentBlockSolid
    href={buttonLink}
    target={buttonTarget}
    colorProfile={colorProfile}
    onClick={() => buttonClick()}
    aria-label={ariaLabel}
    role="button"
    className={`content-block-${blockType} `}
  >
    <div className="cb-content-wrapper">
      <div className="cb-content">
        {(iconName) && <Icon className="cb-icon" name={iconName} width="50" height="50" />}
        <div>
          {(title) && <h3 className="cb-title">{title}</h3>}
          {(copy) && <p className="cb-copy">{copy}</p>}
          {(buttonEnabled && buttonText) && (
            <div className="button with-background uppercase">
              {buttonText}
            </div>
          )}
        </div>
      </div>
    </div>
  </ContentBlockSolid>
);

Solid.propTypes = PropTypes.objectOf(ContentBlockType).isRequired;
