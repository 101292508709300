/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';

import {
  Components, Breakpoints, ThemeProps as Prop, GlobalLayoutValues,
  FontFamily,
  FontSize,
} from '@powdr/constants';
import { getFontStyles, themeUtil } from '@powdr/utils';

const CPNT = Components.UI_SECTION;

export const StyledUiSection = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};
  transition: 1s background-color linear;

  ${({ scrollOffset }) => ((scrollOffset) ? `
    scroll-behavior: smooth;
    scroll-margin-top: ${scrollOffset}px;
  ` : '')}


  ${({ backgroundImage, bgImageHPosition, bgImagePosition }) => backgroundImage && css`
    background-image: url('${backgroundImage}');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: ${bgImageHPosition || 'center'} ${bgImagePosition || 'center'};
  `}

  .section-inner {
    width: 100%;
    overflow: hidden;
    ${({ $isIgnoreMaxWidth }) => (!$isIgnoreMaxWidth) && `max-width: ${GlobalLayoutValues.DESKTOP.WRAPPER_MAX}px;`};
    ${({
    $topPadding, $bottomPadding, $sidePadding,
    hasUiFooterAsChild, hasUiHeaderAsChild, hasNoPaddingGrid,
    isContainer, isMobile,
  }) => {
    let leftRightPaddingPercentage = 0;
    let topPaddingPx = 50;
    let bottomPaddingPx = 50;

    // handle left right padding logic
    if (!$sidePadding) {
      if (isContainer) {
        if (isMobile) leftRightPaddingPercentage = 4.5;
        else leftRightPaddingPercentage = 7.5;
      }
    } else if (isMobile) leftRightPaddingPercentage = 4.5;
    else leftRightPaddingPercentage = $sidePadding;

    // handle top padding
    if ($topPadding === null) {
      if (hasUiHeaderAsChild || hasNoPaddingGrid) topPaddingPx = 0;
    } else topPaddingPx = $topPadding;

    // handle bottom padding
    if ($bottomPadding === null) {
      if (hasUiFooterAsChild || hasNoPaddingGrid) bottomPaddingPx = 0;
    } else bottomPaddingPx = $bottomPadding;

    return `
        margin: ${topPaddingPx}px ${leftRightPaddingPercentage}% ${bottomPaddingPx}px;
        margin: ${topPaddingPx}px round(nearest, ${leftRightPaddingPercentage}%, 1px) ${bottomPaddingPx}px;
      `;
  }}
  }


  // TODO: might need to add this back, maybe not though
  /* h1, h2, h3, h4, h5, h6 {
    color: ${({ colorProfile }) => themeUtil(Prop.HEADER, CPNT, colorProfile)};
  }

  p, li {
    color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
  } */

  /* a:not(.btn) {
    color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT, CPNT, colorProfile)};
    &:hover {
      color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT_HOVER, CPNT, colorProfile)};
    }
  }

  .icon-c {
    svg {
      fill: ${({ colorProfile }) => themeUtil(Prop.ICON, CPNT, colorProfile)};
      color: ${({ colorProfile }) => themeUtil(Prop.ICON, CPNT, colorProfile)};
    }
  } */

  .temp-section-header {
    ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.LRG10, undefined, undefined)};
    text-align: center;
  }

  // TODO: Test larger wrapped breakpoints
  ${Breakpoints.desktopOnly(css`
    .trapezoid-overlay {
      transform: skewX(-20deg);
      pointer-events: none;
      position: absolute;
      border: 1px black solid;
      width: 100%;
      height: 100%;
      z-index: 10;
    }

    .trapezoid-overlay + .section-inner .ui-column, .trapezoid-overlay + .section-inner .ui-column > ui-image {
      padding: 0;

      .ui-image {
        padding: 0;

        img {
          margin: 0;
        }
      }

      .ui-html {
        padding-left: 100px;
      }
    }
  `)}

  .checkbox {
    &+label:before {
      border-color: ${({ colorProfile }) => themeUtil(Prop.BORDER, CPNT, colorProfile)};
    }

    &:hover+label:before {
      background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
    }

    &:focus+label:before {
      background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};
    }

    &:checked+label:before {
      background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile)};
      border-color: ${({ colorProfile }) => themeUtil(Prop.BORDER, CPNT, colorProfile)};
    }

    // Disabled state
    &.m-disabled+label,
    &[disabled]+label {
      color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_INACTIVE, CPNT, colorProfile)};
    }

    &.m-disabled+label:before,
    &[disabled]+label:before {
      background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_INACTIVE, CPNT, colorProfile)};
    }

    &:checked+label:after {
      content: '';
      rotate: 40deg;
      background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
      // TODO: Test and move to a shared function
      box-shadow:
        2px 0 0 ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)},
        4px 0 0 ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)},
        4px -2px 0 ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)},
        4px -4px 0 ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)},
        4px -6px 0 ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)},
        4px -8px 0 ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
      position: absolute;
      top: 8px;
      left: 4px;
      width: 2px;
      height: 2px;
    }
  }
`;

export const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;

  .loader-404 {
    height: auto;
    width: auto;
  }
`;
