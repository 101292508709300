import PropTypes from 'prop-types';
import React, { useState, useContext } from 'react';

import { UiAccordion, UiAccordionPanel, UiDrawer } from '@powdr/components';
import { FilterDefaultState, trailFilter } from '@powdr/constants';
import { AppContext } from '@powdr/context';
import { useIsInView } from '@powdr/hooks';

import { DorCheckboxGroup } from './dor-checkbox-group';
import {
  DataShape, FiltersShape,
  SettingsShape, ThemeShape,
} from './dor-prop-types';
import { StyledDorDrawer, StyledLayoutFilters } from './styles';

export const DorLiftsTrailsDrawer = ({
  data, settings, theme, filters,
  onFilterChange,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { toggleBodyScroll } = useContext(AppContext);
  const isInView = useIsInView(settings.contentRef);

  const handleCheckboxClick = (updatedFilters) => {
    onFilterChange(updatedFilters, { type: 'trail' });
  };

  const handleOnDrawerOpen = () => {
    setIsDrawerOpen(true);
    toggleBodyScroll(true);
  };

  const handleOnDrawerClose = () => {
    setIsDrawerOpen(false);
    toggleBodyScroll(false);
  };

  return (
    <StyledLayoutFilters>
      <StyledDorDrawer
        className={`${settings.isDesktop && 'sticky'}`}
        colorProfile={theme.parentColorProfile}
        top={`${settings.drawerStickyOffset}`}
      >
        <UiDrawer
          isDrawerOpen={isDrawerOpen}
          isDrawerEnabled={settings.isMobile && isInView}
          handleDrawOpen={handleOnDrawerOpen}
          handleCanvasClose={handleOnDrawerClose}
          drawerOffsetTop={`${settings.drawerStickyOffset}`}
          drawerWidth={310}
        >
          {settings.generalDrawr.FITLER_HEADER
            && data.conditionsData?.parkUpdate
            && (
              <div className="drawr-fitler-header">
                <h3>
                  Total Features:
                  {data.conditionsData?.parkUpdate[0]?.total_features || 0}
                </h3>
              </div>
            )}
          <UiAccordion panelDefaultState={filters?.filterDefaultState === FilterDefaultState.OPEN}>
            {trailFilter?.map((grp, i) => (!settings.hideFilterDrawer?.includes(grp.id) ? (
              <UiAccordionPanel
                id={`test-${i}`}
                panelId={`${i}`}
                label={grp.label}
                style={{ zIndex: `${100 - i}` }}
              >
                <DorCheckboxGroup
                  id={grp.id}
                  className="animated fadeInUp u-an-de-0"
                  layout="list"
                  inputLabelTitle={grp.label}
                  onFiltersChange={handleCheckboxClick}
                  checkInputsFields={grp.fields?.filter((f) => ![
                    ...settings.hideTrailStatus || [],
                    ...settings.hideTrailDifficulty || [],
                    ...settings.hideTrailFeature || [],
                    ...settings.hideTrailFeatureDiff || [],
                    ...settings.hideTrailFeatureSize || [],
                    ...settings.hideTrailGroomedStatus || [],
                  ].includes(f.id))}
                />
              </UiAccordionPanel>
            ) : null))}
          </UiAccordion>
        </UiDrawer>
      </StyledDorDrawer>
    </StyledLayoutFilters>
  );
};

DorLiftsTrailsDrawer.propTypes = {
  data: DataShape.isRequired,
  settings: SettingsShape.isRequired,
  theme: ThemeShape.isRequired,
  filters: FiltersShape.isRequired,
  onFilterChange: PropTypes.func.isRequired,
};
