import styled, { css } from 'styled-components';

import {
  ThemeProps as Prop, Components, StaticProfiles, ComponentGroups, Breakpoints,
} from '@powdr/constants';
import {
  themeUtil, componentFontSize, getDorComponentColor,
} from '@powdr/utils';

const CPNT = Components.DOR;

export const StyledDorAlert = styled.div`
  margin-bottom: 30px;

  &.dor-alert {
    position: relative;

    .bolder {
      font-weight: bolder;
    }

    .icon {
      display: flex;
      justify-content: center;

      svg {
        fill: ${({ isParentNavFeed, colorProfile }) => getDorComponentColor(isParentNavFeed, colorProfile, Prop.ICON, CPNT)};
        color: ${({ isParentNavFeed, colorProfile }) => getDorComponentColor(isParentNavFeed, colorProfile, Prop.ICON, CPNT)};
      }
    }

    &.alert {

      .success {
        padding: 15px;
        background-color: ${() => themeUtil(Prop.SUCCESS, CPNT, StaticProfiles.ALERTS)};
      }

      .info {
        padding: 15px;
        background-color: ${() => themeUtil(Prop.INFO, CPNT, StaticProfiles.ALERTS)};
      }

      .warning {
        padding: 15px;
        background-color: ${() => themeUtil(Prop.WARNING, CPNT, StaticProfiles.ALERTS)};
      }

      .danger {
        padding: 15px;
        background-color: ${() => themeUtil(Prop.DANGER, CPNT, StaticProfiles.ALERTS)};
      }
    }
  }

`;
